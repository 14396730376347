import styled from "@emotion/styled";
import { Input } from "@lightspeed/flame/Input";
import { Button } from "@lightspeed/flame/Button";
import { useStoreActions, useStoreState } from "easy-peasy";
import Select from "react-select";
import { Creatable } from "react-select";
import { useState } from "react";

const PickupInstructionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const PickupLabel = styled.p`
  margin: 8px 0;
  width: 250px;
`;

const PickupInstructionStyle = styled.div`
  flex: 1;
  display: flex;

  p {
    width: 250px;
  }
`;

const TimeWrapper = styled.div`
  margin-right: 8px;
  display: flex;
  flex-direction: column;
  input[type="time"],
  input[type="date"] {
    border: solid 1px #ccc;
    margin-top: 2px;
    padding: 6px 0;
  }
`;

const SelectWrapper = styled.div`
  flex: 1;
`;

const PickupInstruction = ({ props }) => {
  const { instructionList } = useStoreState((props) => props.consignments);

  const {
    senderPickupInstruction,
    senderPickupInstructionSet,
    readyTime,
    readyTimeSet,
    closeTime,
    closeTimeSet,
    pickupDate,
    pickupDateSet,
  } = props;
  return (
    <PickupInstructionWrapper>
      <PickupLabel>Pickup Instruction</PickupLabel>
      <PickupInstructionStyle>
        {/* <SelectWrapper>
          <label htmlFor="mySelect">Pickup Req</label>
          <Creatable
            className="sender-select"
            name="mySelect"
            // id={name}
            options={instructionList.map((item) => ({
              label: item,
              value: item,
            }))}
            value={senderPickupInstruction}
            onChange={(e) => senderPickupInstructionSet(e)}
          />
        </SelectWrapper> */}
        <TimeWrapper>
          <label htmlFor="myTime">Pickup Date</label>
          <input
            type="date"
            value={pickupDate}
            onChange={(e) => pickupDateSet(e.target.value)}
          />
        </TimeWrapper>
        <TimeWrapper>
          <label htmlFor="myTime">Ready Time</label>
          <input
            type="time"
            value={readyTime}
            onChange={(e) => readyTimeSet(e.target.value)}
          />
        </TimeWrapper>
        <TimeWrapper>
          <label htmlFor="myTime2">Close Time</label>
          <input
            type="time"
            value={closeTime}
            onChange={(e) => closeTimeSet(e.target.value)}
          />
        </TimeWrapper>
      </PickupInstructionStyle>
    </PickupInstructionWrapper>
  );
};
const PickupInstruction2 = ({ props }) => {
  const { instructionList } = useStoreState((props) => props.consignments);

  const {
    receiverPickupInstrucion,
    receiverPickupInstrucionSet,
    deliveryDate,
    deliveryDateSet,
    deliveryTime,
    deliveryTimeSet,
  } = props;
  return (
    <PickupInstructionWrapper>
      <PickupLabel>Pickup Instruction</PickupLabel>
      <PickupInstructionStyle>
        <SelectWrapper>
          <label htmlFor="mySelect2">Pickup Req</label>
          <Creatable
            className="sender-select"
            id="mySelect2"
            name="mySelect2"
            options={instructionList.map((item) => ({
              label: item,
              value: item,
            }))}
            value={receiverPickupInstrucion}
            onChange={(e) => receiverPickupInstrucionSet(e)}
          />
        </SelectWrapper>

        <TimeWrapper>
          <label htmlFor="myTime">Delivery Date</label>
          <input
            type="date"
            value={deliveryDate}
            onChange={(e) => deliveryDateSet(e.target.value)}
          />
        </TimeWrapper>
        <TimeWrapper>
          <label htmlFor="myTime2">Delivery Time</label>
          <input
            type="time"
            value={deliveryTime}
            onChange={(e) => deliveryTimeSet(e.target.value)}
          />
        </TimeWrapper>
      </PickupInstructionStyle>
    </PickupInstructionWrapper>
  );
};

const GroupWrapper = styled.div`
  margin-right: 8px;
  display: flex;
  margin-bottom: 4px;
  position: relative;

  label {
    margin-bottom: 6px;
    display: block;
    min-width: 250px;
  }

  .sender-select {
    width: 100%;
  }
`;

const SelectGroup = ({ label, name, options, value, valueSet }) => {
  const { theme } = useStoreState((props) => props.ui);

  const customStyle = {
    option: (base, state) => ({
      ...base,
      backgroundColor: theme === "flame" ? "#fff" : "#333",
      color: theme === "flame" ? "#333" : "#fff",
    }),
  };

  return (
    <GroupWrapper>
      <label htmlFor={name}>{label}</label>
      <Select
        className="sender-select"
        styles={customStyle}
        name={name}
        id={name}
        options={options}
        value={value}
        onChange={(e) => valueSet(e)}
      />
    </GroupWrapper>
  );
};

const Count = styled.div`
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: 20;
  font-size: 12px;
  opacity: 0.6;
`;

const InputGroup = ({
  label,
  name,
  placeholder = "",
  type = "text",
  value,
  valueSet,
  count,
}) => {
  return (
    <GroupWrapper>
      <label htmlFor={name}>{label}</label>
      <Input
        type={type}
        autoComplete="off"
        value={value}
        onChange={(e) =>
          valueSet((old) => {
            if (count) {
              return e.target.value.length <= count ? e.target.value : old;
            } else {
              return e.target.value;
            }
          })
        }
        name={name}
        id={name}
        placeholder={placeholder}
        style={{ paddingRight: count ? "64px" : "8px" }}
      />
      {count && (
        <Count>
          {value.length}/{count}
        </Count>
      )}
    </GroupWrapper>
  );
};

const SenderWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 32px;

  & > div {
    margin-right: 24px;
  }
`;

const Empty = styled.div`
  height: 40px;
`;

const ButtonWrapper = styled.div`
  padding-right: 8px;
  padding-top: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  p {
    margin-right: 40px;
    font-weight: 600;
  }
`;

const SpecialWrapper = styled.div`
  grid-column: span 2;
`;

const SenderReceiver = ({ props }) => {
  const { senders, receivers, template } = useStoreState(
    (props) => props.consignments
  );
  const { createConsignment, getConsignments, updateConsignment } =
    useStoreActions((props) => props.consignments);
  const { user } = useStoreState((props) => props.auth);
  const { addConsignment } = useStoreState((props) => props.ui);
  const { setAddConsignment } = useStoreActions((props) => props.ui);
  const [errorMsg, errorMsgSet] = useState("");

  const {
    sender,
    senderSet,
    senderAddress1,
    senderAddress1Set,
    senderAddress2,
    senderAddress2Set,
    senderSuburb,
    senderSuburbSet,
    senderContact,
    senderContactSet,
    senderPhone,
    senderPhoneSet,
    senderSendingCity,
    senderSendingCitySet,
    senderInstructions,
    senderInstructionsSet,
    receiver,
    receiverSet,
    receiverAddress1,
    receiverAddress1Set,
    receiverAddress2,
    receiverAddress2Set,
    receiverSuburb,
    receiverSuburbSet,
    receiverContact,
    receiverContactSet,
    receiverPhone,
    receiverPhoneSet,
    receiverInstructions,
    receiverInstructionsSet,
    templateCarrier,
    templateDate,
    templateWhoPays,
    selectService,
    selectConnote,
    specialInstruction,
    specialInstructionSet,
    senderPickupInstruction,
    senderPickupInstructionSet,
    receiverPickupInstrucion,
    receiverPickupInstrucionSet,
    pickupDate,
    pickupDateSet,
    readyTime,
    readyTimeSet,
    closeTime,
    closeTimeSet,
    deliveryDate,
    deliveryDateSet,
    deliveryTime,
    deliveryTimeSet,
    deliveryRef,
    deliveryRefSet,
  } = props;

  const handleSubmit = () => {
    if (!templateDate) {
      errorMsgSet("Date is required.");
      return;
    }
    if (!templateCarrier) {
      errorMsgSet("Carrier is required.");
      return;
    }
    if (!templateWhoPays?.label) {
      errorMsgSet("Who pays is required.");
      return;
    }
    if (!selectService?.value) {
      errorMsgSet("Service is required.");
      return;
    }
    // if (!selectConnote?.value) {
    //   errorMsgSet("Connote type is required.");
    //   return;
    // }
    if (!sender?.value?.id) {
      errorMsgSet("Sender is required.");
      return;
    }
    if (!senderAddress1) {
      errorMsgSet("Sender Address 1 is required.");
      return;
    }
    if (!senderAddress2) {
      errorMsgSet("Sender Address 2 is required.");
      return;
    }
    if (!senderSuburb) {
      errorMsgSet("Sender Suburb is required.");
      return;
    }
    /*if (!senderContact) {
      errorMsgSet("Sender Contact is required.");
      return;
    }*/
    if (!senderSendingCity) {
      errorMsgSet("Sender City is required.");
      return;
    }
    if (!receiver?.value?.id) {
      errorMsgSet("Receiver is required.");
      return;
    }
    if (!receiverAddress1) {
      errorMsgSet("Receiver Address 1 is required.");
      return;
    }
    if (!receiverAddress2) {
      errorMsgSet("Receiver Address 2 is required.");
      return;
    }
    if (!receiverSuburb) {
      errorMsgSet("Receiver Suburb is required.");
      return;
    }
    /*
    if (!receiverContact) {
      errorMsgSet("Receiver Contact is required.");
      return;
    }*/
    errorMsgSet("");

    const onSuccess = (item) => {
      errorMsgSet("");
      if (addConsignment?.type === "create")
        setAddConsignment({ type: "update", item });
      if (addConsignment?.type === "update") errorMsgSet("update success!");
      getConsignments({ user });
    };

    if (addConsignment?.type === "create") {
      createConsignment({
        user,
        onSuccess,
        e: {
          despatch_date: templateDate,
          carrier: templateCarrier,
          who_pays: templateWhoPays.label,
          service_id: selectService.value,
          connote_id: selectConnote.value,
          sender_id: sender.value.id,
          sender_address1: senderAddress1,
          sender_address2: senderAddress2,
          sender_subrub: senderSuburb,
          sender_contact: senderContact,
          sender_phone: senderPhone,
          sender_city: senderSendingCity,
          sender_instruction: senderInstructions,
          receiver_id: receiver.value.id,
          receiver_address1: receiverAddress1,
          receiver_address2: receiverAddress2,
          receiver_subrub: receiverSuburb,
          receiver_contact: receiverContact,
          receiver_phone: receiverPhone,
          receiver_instruction: receiverInstructions,
          pickup_req: senderPickupInstruction.value,
          pickup_date: pickupDate,
          pickup_ready_time: readyTime,
          pickup_close_time: closeTime,
          delivery_req: receiverPickupInstrucion.value,
          delivery_date: deliveryDate,
          delivery_time: deliveryTime,
          delivery_reference: deliveryRef,
          special_instruction: specialInstruction,
        },
      });
    }
    if (addConsignment?.type === "update") {
      updateConsignment({
        user,
        onSuccess,
        e: {
          id: addConsignment.item.id,
          despatch_date: templateDate,
          carrier: templateCarrier,
          who_pays: templateWhoPays.label,
          service_id: selectService.value,
          connote_id: selectConnote.value,
          sender_id: sender.value.id,
          sender_address1: senderAddress1,
          sender_address2: senderAddress2,
          sender_subrub: senderSuburb,
          sender_contact: senderContact,
          sender_phone: senderPhone,
          sender_city: senderSendingCity,
          sender_instruction: senderInstructions,
          receiver_id: receiver.value.id,
          receiver_address1: receiverAddress1,
          receiver_address2: receiverAddress2,
          receiver_subrub: receiverSuburb,
          receiver_contact: receiverContact,
          receiver_phone: receiverPhone,
          receiver_instruction: receiverInstructions,
          pickup_req: senderPickupInstruction.value,
          pickup_date: pickupDate,
          pickup_ready_time: readyTime,
          pickup_close_time: closeTime,
          delivery_req: receiverPickupInstrucion.value,
          delivery_date: deliveryDate,
          delivery_time: deliveryTime,
          delivery_reference: deliveryRef,
          special_instruction: specialInstruction,
        },
      });
    }
  };
  return (
    <SenderWrapper>
      <div>
        <SelectGroup
          options={senders.map((item) => ({
            label: item.name,
            value: item,
          }))}
          label="Sender"
          name="sender"
          value={sender}
          valueSet={senderSet}
        />
        <InputGroup
          value={senderAddress1}
          valueSet={senderAddress1Set}
          label="Address 1"
          name="senderAddress1"
        />
        <InputGroup
          value={senderAddress2}
          valueSet={senderAddress2Set}
          label="Address 2"
          name="senderAddress2"
        />
        <InputGroup
          value={senderSuburb}
          valueSet={senderSuburbSet}
          label="Suburb"
          name="senderSuburb"
        />
        <InputGroup
          value={senderContact}
          valueSet={senderContactSet}
          label="Contact"
          name="senderContact"
        />
        <InputGroup
          value={senderPhone}
          valueSet={senderPhoneSet}
          label="Phone"
          name="senderPhone"
        />
        <InputGroup
          value={senderSendingCity}
          valueSet={senderSendingCitySet}
          label="Sending City"
          name="senderSendingCity"
        />
        <InputGroup
          value={senderInstructions}
          valueSet={senderInstructionsSet}
          label="Sender Instructions"
          name="senderInstructions"
          count={75}
        />
      </div>
      <div>
        <SelectGroup
          options={receivers.map((item) => ({
            label: item.name,
            value: item,
          }))}
          label="Receiver"
          name="receiver"
          value={receiver}
          valueSet={receiverSet}
        />
        <InputGroup
          value={receiverAddress1}
          valueSet={receiverAddress1Set}
          label="Address 1"
          name="receiverAddress1"
        />
        <InputGroup
          value={receiverAddress2}
          valueSet={receiverAddress2Set}
          label="Address 2"
          name="receiverAddress2"
        />
        <InputGroup
          value={receiverSuburb}
          valueSet={receiverSuburbSet}
          label="Suburb"
          name="receiverSuburb"
        />
        <InputGroup
          value={receiverContact}
          valueSet={receiverContactSet}
          label="Contact"
          name="receiverContact"
        />
        <InputGroup
          value={receiverPhone}
          valueSet={receiverPhoneSet}
          label="Phone"
          name="receiverPhone"
        />
        <Empty />
        <InputGroup
          value={receiverInstructions}
          valueSet={receiverInstructionsSet}
          label="Receiver Instructions"
          name="receiverInstructions"
          count={75}
        />
      </div>
      <SpecialWrapper>
        <InputGroup
          value={specialInstruction}
          valueSet={specialInstructionSet}
          label="Special Instruction"
          name="specialInstruction"
          count={75}
        />
      </SpecialWrapper>
      <PickupInstruction
        props={{
          senderPickupInstruction,
          senderPickupInstructionSet,
          readyTime,
          readyTimeSet,
          closeTime,
          closeTimeSet,
          pickupDate,
          pickupDateSet,
        }}
        label1="Ready Time"
        label2="Close Time"
      />
      {/* <PickupInstruction2
        props={{
          receiverPickupInstrucion,
          receiverPickupInstrucionSet,
          deliveryDate,
          deliveryDateSet,
          deliveryTime,
          deliveryTimeSet,
        }}
        label1="Delivery Date"
        label2="Delivery Time"
      /> */}

      <div style={{ marginTop: "48px" }}>
        <InputGroup
          value={deliveryRef}
          valueSet={deliveryRefSet}
          label="Receiver Ref"
          name="deliveryRef"
        />
      </div>

      <ButtonWrapper>
        <Button fill="true" variant="primary" onClick={handleSubmit}>
          {(addConsignment?.type === "update" && "Update") ||
            (addConsignment?.type === "create" && "Create")}
          {" Consignment"}
        </Button>
        <p>{errorMsg}</p>
      </ButtonWrapper>
    </SenderWrapper>
  );
};

export default SenderReceiver;
