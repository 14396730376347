const ModalWrapper = ({ children, onClick }) => {
  return (
    <div className="modal-wrapper">
      <div onClick={onClick} className="modal-wrapper-bg" />
      {children}
    </div>
  );
};

export default ModalWrapper;
