import axios from "axios";
import { action, thunk } from "easy-peasy";

const axiosGetUsers = async (payload) => {
  const { user } = payload;
  const options = {
    method: "post",
    url: `https://api.ntsolutions.com.au/api/post`,
    headers: {
      "X-Operation-Id": "UserList",
      "X-Company-Id": user.user_company,
      "X-User-Id": user.user_uuid,
    },
  };
  const resp = await axios.request(options);
  return resp;
};

const axiosCreateUser = async (payload) => {
  const { user, e } = payload;
  const data = JSON.stringify({
    user_username: e.username,
    user_password: e.password,
    user_fname: e.fname,
    user_lname: e.lname,
    user_email: e.email,
    user_phone: e.phone,
  });
  const options = {
    method: "post",
    url: `https://api.ntsolutions.com.au/api/post`,
    headers: {
      "X-Operation-Id": "UserListInsert",
      "X-Company-Id": user.user_company,
      "X-User-Id": user.user_uuid,
      "Content-Type": "application/json",
    },
    data: data,
  };
  const resp = await axios.request(options);
  return resp;
};

const axiosUpdateUser = async (payload) => {
  const { user, e } = payload;
  const data = JSON.stringify({
    user_uuid: e.uuid,
    user_password: e.password,
    user_fname: e.fname,
    user_lname: e.lname,
    user_email: e.email,
    user_phone: e.phone,
  });

  const options = {
    method: "post",
    url: `https://api.ntsolutions.com.au/api/post`,
    headers: {
      "X-Operation-Id": "UserListUpdate",
      "X-Company-Id": user.user_company,
      "X-User-Id": user.user_uuid,
      "Content-Type": "application/json",
    },
    data: data,
  };

  const resp = await axios.request(options);
  return resp;
};

const axiosDeleteUser = async (payload) => {
  const { user, id } = payload;
  const data = JSON.stringify({
    user_id: id,
  });

  const options = {
    method: "post",
    url: `https://api.ntsolutions.com.au/api/post`,
    headers: {
      "X-Operation-Id": "UserListDelete",
      "X-Company-Id": user.user_company,
      "X-User-Id": user.user_uuid,
      "Content-Type": "application/json",
    },
    data: data,
  };
  const resp = await axios.request(options);
  return resp;
};

const axiosUsernameCheck = async (payload) => {
  const { user, username } = payload;
  const data = JSON.stringify({
    user_username: username,
  });

  const options = {
    method: "post",
    url: `https://api.ntsolutions.com.au/api/post`,
    headers: {
      "X-Operation-Id": "UserListInsertUserNameChk",
      "X-Company-Id": user.user_company,
      "X-User-Id": user.user_uuid,
      "Content-Type": "application/json",
    },
    data: data,
  };
  const resp = await axios.request(options);
  return resp;
};

export const usersModel = {
  users: [],
  setUsers: action((state, payload) => {
    state.users = payload;
  }),
  getUsers: thunk(async (actions, payload) => {
    const resp = await axiosGetUsers(payload);
    actions.setUsers(resp.data);
  }),
  createUser: thunk((actions, payload) => {
    axiosCreateUser(payload)
      .then((resp) => payload.onSuccess())
      .catch((err) => payload.errorTextSet("Create customer failed!"));
  }),
  deleteUser: thunk((actions, payload) => {
    axiosDeleteUser(payload)
      .then((resp) => payload.onSuccess())
      .catch((err) => console.log(err));
  }),
  updateUser: thunk((actions, payload) => {
    axiosUpdateUser(payload)
      .then((resp) => payload.onSuccess())
      .catch((err) => console.log(err));
  }),
  usernameCheck: thunk((actions, payload) => {
    axiosUsernameCheck(payload)
      .then((resp) => payload.onSuccess())
      .catch((err) => payload.onFail());
  }),
};
