import React, { useRef, useState } from "react";

import AdminTable from "../tables/AdminTable";
import SendTable from "../tables/user/SendTable";
import SimpleTable from "../tables/user/SimpleTable";
import ConsignmentPage from "../tables/user/ConsignmentPage";
import { useStoreState } from "easy-peasy";

const TableController = () => {
  const [sortState, setSortState] = useState([...Array(20)].map(() => 0));
  const [scrollState, setScrollState] = useState(true);
  const scrollRef = useRef({ current: 0 });
  const { searchQuery, customerTable } = useStoreState((props) => props.ui);
  const { users } = useStoreState((props) => props.users);
  const { customers } = useStoreState((props) => props.customers);
  const { itemTypes, serviceTypes, connoteTypes } = useStoreState(
    (props) => props.types
  );
  const { consignments } = useStoreState((props) => props.consignments);

  const handleScroll = () => {
    if (scrollRef.current.scrollTop > 0) {
      if (scrollState !== false) {
        setScrollState(false);
      }
    } else {
      if (scrollState !== true) {
        setScrollState(true);
      }
    }
  };

  const changeSortState = (x) => {
    const newSortState = [...Array(20)].map(() => 0);
    switch (sortState[x]) {
      case 0:
        newSortState[x] = 1;
        break;
      case 1:
        newSortState[x] = 2;
        break;
      default:
        newSortState[x] = 0;
    }
    setSortState([...newSortState]);
  };

  let searchedData;

  switch (customerTable) {
    case "admin":
      searchedData = users.length
        ? users.filter(
            (item) =>
              String(item.user_id).indexOf(searchQuery.toLowerCase()) > -1 ||
              item.user_username
                .toLowerCase()
                .indexOf(searchQuery.toLowerCase()) > -1 ||
              item.user_fname.toLowerCase().indexOf(searchQuery.toLowerCase()) >
                -1 ||
              item.user_lname.toLowerCase().indexOf(searchQuery.toLowerCase()) >
                -1 ||
              item.user_email.toLowerCase().indexOf(searchQuery.toLowerCase()) >
                -1 ||
              item.user_phone.toLowerCase().indexOf(searchQuery.toLowerCase()) >
                -1
          )
        : [];
      break;
    case "send":
      searchedData = customers.length
        ? customers.filter(
            (item) =>
              item.name.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1 ||
              item.address_line_1
                .toLowerCase()
                .indexOf(searchQuery.toLowerCase()) > -1 ||
              item.address_line_2
                .toLowerCase()
                .indexOf(searchQuery.toLowerCase()) > -1 ||
              item.city.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1 ||
              item.contact.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1 ||
              item.country.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1 ||
              item.email.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1 ||
              item.fax.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1 ||
              //item.lat.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1 ||
              //item.lng.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1 ||
              item.note.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1 ||
              item.phone.toLowerCase().indexOf(searchQuery.toLowerCase()) >-1 ||
              item.postal_code
                .toLowerCase()
                .indexOf(searchQuery.toLowerCase()) > -1 ||
              item.receiver_instruction
                .toLowerCase()
                .indexOf(searchQuery.toLowerCase()) > -1 ||
              item.sender_instructions
                .toLowerCase()
                .indexOf(searchQuery.toLowerCase()) > -1
          )
        : [];
      break;
    case "itemtype":
      searchedData = itemTypes.length
        ? itemTypes.filter(
            (item) =>
              String(item.id).indexOf(searchQuery.toLowerCase()) > -1 ||
              item.item_name.toLowerCase().indexOf(searchQuery.toLowerCase()) >
                -1
          )
        : [];
      break;
    case "servicetype":
      searchedData = serviceTypes.length
        ? serviceTypes.filter(
            (item) =>
              String(item.id).indexOf(searchQuery.toLowerCase()) > -1 ||
              item.item_name.toLowerCase().indexOf(searchQuery.toLowerCase()) >
                -1
          )
        : [];
      break;
    case "connotetype":
      searchedData = connoteTypes.length
        ? connoteTypes.filter(
            (item) =>
              String(item.id).indexOf(searchQuery.toLowerCase()) > -1 ||
              item.item_name.toLowerCase().indexOf(searchQuery.toLowerCase()) >
                -1
          )
        : [];
      break;
    case "consignment":
      searchedData = consignments.length
        ? consignments.filter(
            (item) =>
              String(item.id).indexOf(searchQuery) > -1 ||
              item.sender_name
                .toLowerCase()
                .indexOf(searchQuery.toLowerCase()) > -1 ||
              item.receiver_name
                .toLowerCase()
                .indexOf(searchQuery.toLowerCase()) > -1
          )
        : [];
      break;
    default:
      searchedData = [];
  }

  const tables = {
    admin: (
      <AdminTable
        scrollPos={scrollState}
        data={searchedData}
        sortState={sortState}
        changeSortState={changeSortState}
      />
    ),

    send: (
      <SendTable
        scrollPos={scrollState}
        data={searchedData}
        sortState={sortState}
        changeSortState={changeSortState}
      />
    ),
    itemtype: (
      <SimpleTable
        scrollPos={scrollState}
        data={searchedData}
        sortState={sortState}
        changeSortState={changeSortState}
      />
    ),
    servicetype: (
      <SimpleTable
        scrollPos={scrollState}
        data={searchedData}
        sortState={sortState}
        changeSortState={changeSortState}
      />
    ),
    connotetype: (
      <SimpleTable
        scrollPos={scrollState}
        data={searchedData}
        sortState={sortState}
        changeSortState={changeSortState}
      />
    ),
    consignment: (
      <ConsignmentPage
        props={{ scrollState, searchedData, sortState, changeSortState }}
      />
    ),
  };

  return (
    <div className="table-content" onScroll={handleScroll} ref={scrollRef}>
      {tables[customerTable]}
    </div>
  );
};

export default TableController;
