import axios from "axios";
import { action, thunk } from "easy-peasy";

const axiosGetTypes = async (payload) => {
  const { operation, user } = payload;
  const options = {
    method: "post",
    url: `https://api.ntsolutions.com.au/api/post`,
    headers: {
      "X-Operation-Id": operation,
      "X-Company-Id": user.user_company,
      "X-User-Id": user.user_uuid,
    },
  };
  const resp = await axios.request(options);
  return resp;
};

const axiosCreateTypes = async (payload) => {
  const { user, e, operation, itemName } = payload;

  var data = JSON.stringify({
    [itemName]: e.name,
  });

  const options = {
    method: "post",
    url: `https://api.ntsolutions.com.au/api/post`,
    headers: {
      "X-Operation-Id": operation,
      "X-Company-Id": user.user_company,
      "X-User-Id": user.user_uuid,
      "Content-Type": "application/json",
    },
    data: data,
  };
  const resp = await axios.request(options);
  return resp;
};

const axiosUpdateTypes = async (payload) => {
  const { user, e, id, operation, itemName } = payload;

  var data = JSON.stringify({
    [itemName]: e.name,
    id,
  });

  const options = {
    method: "post",
    url: `https://api.ntsolutions.com.au/api/post`,
    headers: {
      "X-Operation-Id": operation,
      "X-Company-Id": user.user_company,
      "X-User-Id": user.user_uuid,
      "Content-Type": "application/json",
    },
    data: data,
  };
  const resp = await axios.request(options);
  return resp;
};

const axiosDeleteType = async (payload) => {
  const { user, id, operation } = payload;
  const data = JSON.stringify({
    id,
  });

  const options = {
    method: "post",
    url: `https://api.ntsolutions.com.au/api/post`,
    headers: {
      "X-Operation-Id": operation,
      "X-Company-Id": user.user_company,
      "X-User-Id": user.user_uuid,
      "Content-Type": "application/json",
    },
    data: data,
  };
  const resp = await axios.request(options);
  return resp;
};

export const typesModel = {
  itemTypes: [],
  serviceTypes: [],
  connoteTypes: [],
  setItemTypes: action((state, payload) => {
    state.itemTypes = payload;
  }),
  setServiceTypes: action((state, payload) => {
    state.serviceTypes = payload.map((item) => ({
      ...item,
      item_name: item.service_name,
    }));
  }),
  setConnoteTypes: action((state, payload) => {
    state.connoteTypes = payload.map((item) => ({
      ...item,
      item_name: item.connote_name,
    }));
  }),
  getItemTypes: thunk(async (actions, payload) => {
    const resp = await axiosGetTypes({ ...payload, operation: "GetItemTypes" });
    actions.setItemTypes(resp.data);
  }),
  getServiceTypes: thunk(async (actions, payload) => {
    const resp = await axiosGetTypes({
      ...payload,
      operation: "GetServiceTypes",
    });
    actions.setServiceTypes(resp.data);
  }),
  getConnoteTypes: thunk(async (actions, payload) => {
    const resp = await axiosGetTypes({
      ...payload,
      operation: "GetConnoteTypes",
    });
    actions.setConnoteTypes(resp.data);
  }),
  createItemType: thunk((actions, payload) => {
    axiosCreateTypes({
      ...payload,
      operation: "ItemTypesInsert",
      itemName: "item_name",
    })
      .then((resp) => payload.onSuccess())
      .catch((err) => console.log(err));
  }),
  createServiceType: thunk((actions, payload) => {
    axiosCreateTypes({
      ...payload,
      operation: "ServiceTypesInsert",
      itemName: "service_name",
    })
      .then((resp) => payload.onSuccess())
      .catch((err) => console.log(err));
  }),
  createConnoteType: thunk((actions, payload) => {
    axiosCreateTypes({
      ...payload,
      operation: "ConnoteTypeInsert",
      itemName: "connote_name",
    })
      .then((resp) => payload.onSuccess())
      .catch((err) => console.log(err));
  }),
  updateItemType: thunk((actions, payload) => {
    axiosUpdateTypes({
      ...payload,
      operation: "ItemTypesEdit",
      itemName: "item_name",
    })
      .then((resp) => payload.onSuccess())
      .catch((err) => payload.errorTextSet("Update customer failed!"));
  }),
  updateServiceType: thunk((actions, payload) => {
    axiosUpdateTypes({
      ...payload,
      operation: "ServiceTypesEdit",
      itemName: "service_name",
    })
      .then((resp) => payload.onSuccess())
      .catch((err) => payload.errorTextSet("Update customer failed!"));
  }),
  updateConnoteType: thunk((actions, payload) => {
    axiosUpdateTypes({
      ...payload,
      operation: "ConnoteTypesEdit",
      itemName: "connote_name",
    })
      .then((resp) => payload.onSuccess())
      .catch((err) => payload.errorTextSet("Update customer failed!"));
  }),
  deleteItemType: thunk((actions, payload) => {
    axiosDeleteType({
      ...payload,
      operation: "ItemTypesDelete",
    })
      .then((resp) => payload.onSuccess())
      .catch((err) => console.log(err));
  }),
  deleteServiceType: thunk((actions, payload) => {
    axiosDeleteType({
      ...payload,
      operation: "ServiceTypesDelete",
    })
      .then((resp) => payload.onSuccess())
      .catch((err) => console.log(err));
  }),
  deleteconnoteType: thunk((actions, payload) => {
    axiosDeleteType({
      ...payload,
      operation: "ConnoteTypesDelete",
    })
      .then((resp) => payload.onSuccess())
      .catch((err) => console.log(err));
  }),
};
