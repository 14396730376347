import ModalWrapper from "./ModalWrapper";
import { Input } from "@lightspeed/flame/Input";
import { Button } from "@lightspeed/flame/Button";

import { useForm } from "react-hook-form";
import { useStoreActions, useStoreState } from "easy-peasy";

import { useEffect, useState } from "react";
import Autocomplete from "react-google-autocomplete";

import styled from "@emotion/styled";

const InputWrapper = ({ name, type = "text", placeholder, label, fwdRef }) => {
  return (
    <div className="input-wrapper-customer">
      <label htmlFor={name}>{label}</label>
      <Input
        autoComplete="off"
        id={name}
        name={name}
        type={type}
        placeholder={placeholder}
        ref={fwdRef}
        tabIndex="2"
      />
    </div>
  );
};

const AddressWrapper = ({ fwdRef }) => {
  return (
    <div className="input-wrapper-customer address-wrapper">
      <label className="address-span" htmlFor="address1">
        Address
      </label>
      <div>
        <Input
          autoComplete="off"
          id="address1"
          name="address1"
          type="text"
          placeholder="Address 1"
          ref={fwdRef}
        />
      </div>
      <div>
        <Input
          autoComplete="off"
          className="address-span"
          name="address2"
          type="text"
          placeholder="Address 2"
          ref={fwdRef}
        />
      </div>

      <Input
        autoComplete="off"
        name="city"
        type="text"
        placeholder="City"
        ref={fwdRef}
      />
      <Input
        autoComplete="off"
        name="state"
        type="text"
        placeholder="State"
        ref={fwdRef}
      />
      <Input
        autoComplete="off"
        name="country"
        type="text"
        placeholder="Country"
        ref={fwdRef}
      />
      <Input
        autoComplete="off"
        name="postal_code"
        type="text"
        placeholder="Postal code"
        ref={fwdRef}
      />
    </div>
  );
};
// const GeoWrapper = ({ fwdRef }) => {
//   return (
//     <div className="input-wrapper-customer">
//       <label htmlFor="lat">Geo</label>
//       <div className="instruciton-wrapper">
//         <Input
//           autoComplete="off"
//           id="lat"
//           name="lat"
//           type="text"
//           placeholder="Latitude"
//           ref={fwdRef}
//         />
//         <Input
//           autoComplete="off"
//           name="lng"
//           type="text"
//           placeholder="Longitude"
//           ref={fwdRef}
//         />
//       </div>
//     </div>
//   );
// };

const CheckWrapper = ({ name, label, fwdRef }) => {
  return (
    <div className="check-wrapper">
      <label htmlFor={name}>{label}</label>
      <input ref={fwdRef} type="checkbox" name={name} id={name} />
    </div>
  );
};

const FormWrapper = styled.div`
  ${(props) => props.theme && `background: #393939;`};

  .text-area {
    outline: none;
    /* border: none; */
    ${(props) => props.theme && `background: #222;`}
    ${(props) => props.theme && `color: #fff;`}
  }
`;

const UserCreateCustomer = () => {
  const { register, handleSubmit, errors, setValue } = useForm();
  const { setUserCustomer } = useStoreActions((props) => props.ui);
  const { userCustomer } = useStoreState((props) => props.ui);
  const { user } = useStoreState((props) => props.auth);

  const { getCustomers, createCustomer, updateCustomer } = useStoreActions(
    (props) => props.customers
  );
  const [errorText, errorTextSet] = useState("");
  const [formatedAddress, formatedAddressSet] = useState("");
  const [streetNumber, streetNumberSet] = useState("");
  const { theme } = useStoreState((props) => props.ui);

  useEffect(() => {
    if (errors.name) {
      errorTextSet("Name is required");
      return;
    }
    
    if (errors.contact) {
      errorTextSet("Contact is required");
      return;
    }
    
    if (errors.address1) {
      errorTextSet("Address 1 is required");
      return;
    }
    if (errors.address2) {
      errorTextSet("Address 2 is required");
      return;
    }
    if (errors.city) {
      errorTextSet("City is required");
      return;
    }
    if (errors.state) {
      errorTextSet("State is required");
      return;
    }
    if (errors.country) {
      errorTextSet("Country is required");
      return;
    }
    if (errors.postal_code) {
      errorTextSet("Postal code is required");
      return;
    }

    errorTextSet("");
  }, [errors]);

  useEffect(() => {
    if (userCustomer.type === "update") {
      setValue("name", userCustomer.item.name);
      setValue("contact", userCustomer.item.contact);
      setValue("address1", userCustomer.item.address_line_1);
      setValue("address2", userCustomer.item.address_line_2);
      setValue("city", userCustomer.item.city);
      setValue("state", userCustomer.item.state);
      setValue("country", userCustomer.item.country);
      setValue("postal_code", userCustomer.item.postal_code);
      // setValue("lat", userCustomer.item.lat);
      // setValue("lng", userCustomer.item.lng);
      setValue("email", userCustomer.item.email);
      setValue("phone", userCustomer.item.phone);
      setValue("fax", userCustomer.item.fax);
      setValue("note", userCustomer.item.note);
      setValue("is_sender", userCustomer.item.is_sender);
      setValue("is_receiver", userCustomer.item.is_receiver);
      setValue("sender_instructions", userCustomer.item.sender_instructions);
      setValue("receiver_instructions", userCustomer.item.receiver_instruction);
    }
  }, [userCustomer]);

  const onSubmit = (e) => {
    errorTextSet("");

    const onSuccess = () => {
      getCustomers({ user });
      setUserCustomer("");
    };

    userCustomer.type === "create"
      ? createCustomer({
          e,
          errorTextSet,
          onSuccess,
          user,
          formatedAddress,
          streetNumber,
        })
      : updateCustomer({
          e,
          errorTextSet,
          onSuccess,
          user,
          formatedAddress,
          streetNumber,
          id: userCustomer.item.id,
        });
  };

  return (
    <ModalWrapper onClick={() => setUserCustomer("")}>
      <FormWrapper theme={theme !== "flame"} className="create-customer">
        <form className="" onSubmit={handleSubmit(onSubmit)}>
          <div className="instruciton-wrapper">
            <InputWrapper
              name="name"
              label="Name"
              fwdRef={register({ required: true })}
            />
            <InputWrapper
              name="contact"
              label="Contact"
              fwdRef={register({ required: false })}
            />
          </div>

          <div className="google-autocomplete">
            <Autocomplete
              placeholder=""
              onPlaceSelected={(place) => {
                setValue(
                  "postal_code",
                  place.address_components.find((item) =>
                    item.types.includes("postal_code")
                  )?.short_name
                );
                setValue(
                  "country",
                  place.address_components.find((item) =>
                    item.types.includes("country")
                  )?.long_name
                );
                setValue(
                  "state",
                  place.address_components.find((item) =>
                    item.types.includes("administrative_area_level_1")
                  )?.short_name
                );
                setValue(
                  "city",
                  place.address_components.find((item) =>
                    item.types.includes("locality")
                  )?.long_name
                );
                setValue(
                  "address2",
                  place.address_components.find((item) =>
                    item.types.includes("route")
                  )?.long_name
                );

                const streetNumber = place.address_components.find((item) =>
                  item.types.includes("street_number")
                )?.long_name;

                const subpremise = place.address_components.find((item) =>
                  item.types.includes("subpremise")
                )?.long_name;

                setValue(
                  "address1",
                  subpremise ? `${subpremise} / ${streetNumber}` : streetNumber
                );

                // setValue("lat", place.geometry.location.lat());
                // setValue("lng", place.geometry.location.lng());

                streetNumberSet(streetNumber);
                formatedAddressSet(place.formatted_address);
              }}
              types={["geocode"]}
            />
          </div>

          <AddressWrapper fwdRef={register({ required: true })} />
          {/* <GeoWrapper fwdRef={register} /> */}
          <div className="instruciton-wrapper">
            <InputWrapper
              name="email"
              type="email"
              label="Email"
              fwdRef={register}
            />
            <InputWrapper name="phone" label="Phone" fwdRef={register} />
            <InputWrapper name="fax" label="Fax" fwdRef={register} />
          </div>
          <label htmlFor="note">Note</label>
          <textarea
            className="text-area"
            id="note"
            name="note"
            ref={register()}
          />
          <div className="instruciton-wrapper">
            <CheckWrapper name="is_sender" label="Sender" fwdRef={register} />
            <InputWrapper
              name="sender_instructions"
              label="Sender Instructions"
              fwdRef={register}
            />
          </div>
          <div className="instruciton-wrapper">
            <CheckWrapper
              name="is_receiver"
              label="Receiver"
              fwdRef={register}
            />
            <InputWrapper
              name="receiver_instructions"
              label="Receiver Instructions"
              fwdRef={register}
            />
          </div>

          <p>{errorText}</p>
          <div className="form-buttons-wrapper">
            <Button
              fill="true"
              variant="primary"
              type="button"
              onClick={() => setUserCustomer("")}
            >
              Cancel
            </Button>
            <Button fill="true" variant="primary" type="submit">
              {userCustomer.type === "create"
                ? "Create customer"
                : "Update customer"}
            </Button>
          </div>
        </form>
      </FormWrapper>
    </ModalWrapper>
  );
};

export default UserCreateCustomer;
