import React, { useState } from "react";

import SideBar from "./home/SideBar";

import Busy from "./Busy";
import Notify from "./Notify";
import dots from "../assets/dots.svg";

import CategorieBar from "./home/CategorieBar";
import TableContent from "./home/TableContent";

const Home = ({ user }) => {
  const [showSideBar, setShowSideBar] = useState(true);

  return (
    <div className="home">
      {showSideBar && (
        <SideBar
          isAdmin={user?.user_privileges && user.user_privileges === 99}
        />
      )}

      <div
        className="centered side-bar-toggle"
        onClick={(_) => {
          setShowSideBar((old) => !old);
        }}
      >
        <img src={dots} alt="burger dots" />
      </div>

      <div className="main-section relative">
        {/* {ui.notify.show && <Notify />} */}
        {/* {ui.busy && <Busy />} */}
        <CategorieBar />
        <TableContent />
      </div>
    </div>
  );
};

export default Home;
