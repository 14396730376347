import React from "react";

import { Td, Tr } from "@lightspeed/cirrus-table";

import { useStoreActions, useStoreState } from "easy-peasy";

import trash from "../../../../assets/trash.png";
import whitetrash from "../../../../assets/whitetrash.png";
import edit from "../../../../assets/edit.png";
import whiteedit from "../../../../assets/whiteedit.png";

const Row = ({ item }) => {
  const { setDeleteItem, setAddType } = useStoreActions((props) => props.ui);
  const { customerTable, theme } = useStoreState((props) => props.ui);

  const deleteItem = () => {
    if (customerTable === "itemtype") {
      setDeleteItem({ type: "item", id: item.id });
    }
    if (customerTable === "servicetype") {
      setDeleteItem({ type: "service", id: item.id });
    }
    if (customerTable === "connotetype") {
      setDeleteItem({ type: "connote", id: item.id });
    }
  };

  const updateItem = () => {
    if (customerTable === "itemtype") {
      setAddType({ type: "update_item", item });
    }
    if (customerTable === "servicetype") {
      setAddType({ type: "update_service", item });
    }
    if (customerTable === "connotetype") {
      setAddType({ type: "update_connote", item });
    }
  };

  return (
    <Tr
      textAlign="right"
      className="flame"
      onDoubleClick={updateItem}
      className={`${theme === "flame" ? "" : "dark-table-row"}`}
    >
      <Td>{item.id}</Td>
      <Td>{item.item_name}</Td>
      <Td>
        <img
          className="image-button"
          onClick={deleteItem}
          src={theme === "flame" ? trash : whitetrash}
          alt="delete"
        />
        <img
          className="image-button"
          onClick={updateItem}
          src={theme === "flame" ? edit : whiteedit}
          alt="edit"
        />
      </Td>
    </Tr>
  );
};

export default Row;
