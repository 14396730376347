import React, { useState } from "react";
import { Button } from "@lightspeed/flame/Button";
import { Input } from "@lightspeed/flame/Input";

import { Link } from "react-router-dom";

import { useStoreActions } from "easy-peasy";

const Login = () => {
  const [userValue, setUserValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");
  const [notification, setNotification] = useState({ show: false, msg: "" });
  const { getLoggedIn } = useStoreActions((props) => props.auth);
  const { setCustomerTable } = useStoreActions((props) => props.ui);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (userValue === "") {
      setNotification({ show: true, msg: "Username is required" });
      return;
    }
    if (passwordValue === "") {
      setNotification({ show: true, msg: "Password is required" });
      return;
    }

    getLoggedIn({
      user: userValue,
      pass: passwordValue,
      onSuccess: setCustomerTable,
      setNotification,
    });
  };

  return (
    <form className="login-form" onSubmit={handleSubmit}>
      <label htmlFor="user">Username</label>
      {/* <button
        type="button"
        onClick={() => {
          getLoggedIn({
            user: "admin",
            pass: "admin0411",
            onSuccess: setCustomerTable,
            setNotification,
          });
        }}
      >
        login as admin
      </button>
      <button
        type="button"
        onClick={() => {
          getLoggedIn({
            user: "thampl3",
            pass: "demo421",
            onSuccess: setCustomerTable,
            setNotification,
          });
        }}
      >
        login as user
      </button>
      <button
        type="button"
        onClick={() => {
          getLoggedIn({
            user: "tomo",
            pass: "tomo123",
            onSuccess: setCustomerTable,
            setNotification,
          });
        }}
      >
        login as tomo
      </button>*/}
      {/* <button
        type="button"
        onClick={() => {
          getLoggedIn({
            user: "thampl",
            pass: "demo421",
            onSuccess: setCustomerTable,
            setNotification,
          });
        }}
      >
        login as thampl
      </button> */}
      <Input
        name="user"
        type="text"
        value={userValue}
        placeholder="Username"
        onChange={(e) => setUserValue(e.target.value)}
        tabIndex="1"
      />
      <div className="password-recover-wrapper">
        <label htmlFor="password">Password</label>
        <Link tabIndex="4" to="/recover">
          Forgot password?
        </Link>
      </div>
      <Input
        name="password"
        type="password"
        value={passwordValue}
        onChange={(e) => setPasswordValue(e.target.value)}
        tabIndex="2"
      />
      {notification.show && <p className="form-notifi">{notification.msg}</p>}

      <div className="fl-mb-2">
        <Button
          style={{ marginTop: "32px" }}
          block="false"
          type="submit"
          fill="true"
          variant="primary"
          tabIndex="3"
        >
          Sign in
        </Button>
      </div>
    </form>
  );
};

export default Login;
