import React from "react";

import logoOld from "../assets/logoOld.png";
import logo from "../assets/logo.png";

import Login from "./login/Login";
import Recover from "./login/Recover";

import { useStoreState } from "easy-peasy";

const LoginWrapper = ({ recover }) => {
  const { theme } = useStoreState((state) => state.ui);
  return (
    <div className="login-wrapper">
      <div
        className={`login-box-bg ${
          theme === "flame" ? "" : "login-box-bg-dark"
        }`}
      >
        <div
          className={`login-box ${theme === "flame" ? "" : "login-box-dark"}`}
        >
          {/* <div
            className={`logo-wrapper ${
              theme === "flame" ? "" : "logo-wrapper-dark"
            }`}
          >
            <img src={theme === "flame" ? logoOld : logo} alt="logo" />
          </div> */}
          {recover ? <Recover /> : <Login />}
        </div>
      </div>
    </div>
  );
};

export default LoginWrapper;
