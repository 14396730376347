import ModalWrapper from "./ModalWrapper";
import { Input } from "@lightspeed/flame/Input";
import { Button } from "@lightspeed/flame/Button";

import { useForm } from "react-hook-form";
import { useStoreActions, useStoreState } from "easy-peasy";

import { useEffect, useState } from "react";
import styled from "@emotion/styled";

const InputWrapper = ({
  name,
  type = "text",
  placeholder,
  label,
  fwdRef,
  onChange,
}) => {
  return (
    <div className="input-wrapper-customer">
      <label htmlFor={name}>{label}</label>
      <Input
        autoComplete="off"
        id={name}
        name={name}
        type={type}
        placeholder={placeholder}
        ref={fwdRef}
        tabIndex="2"
        onChange={onChange}
      />
    </div>
  );
};

const FormWrapper = styled.div`
  ${(props) => props.theme && `background: #393939;`};
`;

const CreateType = () => {
  const { register, handleSubmit, errors, setValue } = useForm();
  const { setAddType } = useStoreActions((props) => props.ui);
  const { addType, theme } = useStoreState((props) => props.ui);
  const { user } = useStoreState((props) => props.auth);
  const {
    getItemTypes,
    getServiceTypes,
    getConnoteTypes,
    createItemType,
    createServiceType,
    createConnoteType,
    updateItemType,
    updateServiceType,
    updateConnoteType,
  } = useStoreActions((props) => props.types);
  const [errorText, errorTextSet] = useState("");

  useEffect(() => {
    if (errors.name) {
      errorTextSet("Name is required");
      return;
    }
  }, [errors]);

  useEffect(() => {
    if (!addType) return;
    if (addType.type === "update_item") {
      setValue("name", addType.item.item_name);
    }
    if (addType.type === "update_service") {
      setValue("name", addType.item.service_name);
    }
    if (addType.type === "update_connote") {
      setValue("name", addType.item.connote_name);
    }
  }, [addType]);

  const onSubmit = (e) => {
    errorTextSet("");

    if (!addType) return;

    const onSuccess = () => {
      if (addType.type.includes("item")) {
        getItemTypes({ user });
      }
      if (addType.type.includes("service")) {
        getServiceTypes({ user });
      }
      if (addType.type.includes("connote")) {
        getConnoteTypes({ user });
      }
      setAddType("");
    };

    if (addType.type === "create_item") {
      createItemType({ user, e, onSuccess, errorTextSet });
    }
    if (addType.type === "create_service") {
      createServiceType({ user, e, onSuccess, errorTextSet });
    }
    if (addType.type === "create_connote") {
      createConnoteType({ user, e, onSuccess, errorTextSet });
    }
    if (addType.type === "update_item") {
      updateItemType({ user, e, id: addType.item.id, onSuccess, errorTextSet });
    }
    if (addType.type === "update_service") {
      updateServiceType({
        user,
        e,
        id: addType.item.id,
        onSuccess,
        errorTextSet,
      });
    }
    if (addType.type === "update_connote") {
      updateConnoteType({
        user,
        e,
        id: addType.item.id,
        onSuccess,
        errorTextSet,
      });
    }
  };

  return (
    <ModalWrapper onClick={() => setAddType("")}>
      <FormWrapper theme={theme !== "flame"} className="create-customer">
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputWrapper
            name="name"
            type="text"
            placeholder=""
            label="Type name"
            fwdRef={register({ required: true })}
          />
          <p>{errorText}</p>
          <div className="types-form-buttons-wrapper">
            <Button
              fill="true"
              variant="primary"
              type="button"
              onClick={() => setAddType("")}
            >
              Cancel
            </Button>
            <Button fill="true" variant="primary" type="submit">
              {addType.type && addType.type.includes("create")
                ? "Create type"
                : "Update type"}
            </Button>
          </div>
        </form>
      </FormWrapper>
    </ModalWrapper>
  );
};

export default CreateType;
