import React from "react";

import { Td, Tr } from "@lightspeed/cirrus-table";

import { useStoreActions, useStoreState } from "easy-peasy";

import trash from "../../../assets/trash.png";
import whitetrash from "../../../assets/whitetrash.png";
import edit from "../../../assets/edit.png";
import whiteedit from "../../../assets/whiteedit.png";

const Row = ({ item }) => {
  const { setDeleteItem, setCustomer } = useStoreActions((props) => props.ui);
  const { theme } = useStoreState((props) => props.ui);

  return (
    <Tr
      textAlign="right"
      className="flame"
      onDoubleClick={() => setCustomer({ type: "update", item })}
      className={`${theme === "flame" ? "" : "dark-table-row"}`}
    >
      <Td>{item.user_id}</Td>
      <Td>{item.user_username}</Td>
      <Td>privilages</Td>
      <Td>{item.user_fname}</Td>

      <Td>{item.user_lname}</Td>
      <Td>{item.user_email}</Td>
      <Td>{item.user_phone}</Td>
      <Td>
        <img
          className="image-button"
          onClick={() => {
            setDeleteItem({
              type: "admin",
              id: item.user_uuid,
            });
          }}
          src={theme === "flame" ? trash : whitetrash}
          alt="delete"
        />
        <img
          className="image-button"
          onClick={() => {
            setCustomer({ type: "update", item });
          }}
          src={theme === "flame" ? edit : whiteedit}
          alt="edit"
        />
      </Td>
    </Tr>
  );
};

export default Row;
