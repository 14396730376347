import LinesTable from "./lines/LinesTable";
import { Button } from "@lightspeed/flame/Button";
import { Card } from "@lightspeed/flame/Card";

import styled from "@emotion/styled";

import { useStoreActions, useStoreState } from "easy-peasy";
import { useState, useEffect } from "react";

const ButtonWrapper = styled.div`
  text-align: center;
  padding: 16px;
`;

const Lines = ({ data }) => {
  const { setAddConsignmentLine } = useStoreActions((props) => props.ui);
  const { addConsignment } = useStoreState((props) => props.ui);
  const [sortState, setSortState] = useState([...Array(20)].map(() => 0));
  const [scrollState, setScrollState] = useState(true);
  const [searchedData, searchedDataSet] = useState([]);
  const { searchQuery } = useStoreState((props) => props.ui);

  const changeSortState = (x) => {
    const newSortState = [...Array(20)].map(() => 0);
    switch (sortState[x]) {
      case 0:
        newSortState[x] = 1;
        break;
      case 1:
        newSortState[x] = 2;
        break;
      default:
        newSortState[x] = 0;
    }
    setSortState([...newSortState]);
  };

  useEffect(() => {
    searchedDataSet(
      data.length
        ? data.filter(
            (item) =>
              String(item.spaces).indexOf(searchQuery) > -1 ||
              String(item.weight).indexOf(searchQuery) > -1 ||
              String(item.length).indexOf(searchQuery) > -1 ||
              String(item.width).indexOf(searchQuery) > -1 ||
              String(item.height).indexOf(searchQuery) > -1 ||
              String(item.cubic).indexOf(searchQuery) > -1 ||
              item.customer_ref
                .toLowerCase()
                .indexOf(searchQuery.toLowerCase()) > -1 ||
              item.description
                .toLowerCase()
                .indexOf(searchQuery.toLowerCase()) > -1 ||
              item.item_name.toLowerCase().indexOf(searchQuery.toLowerCase()) >
                -1
          )
        : []
    );
  }, [searchQuery, data]);

  return (
    <Card>
      <LinesTable
        data={searchedData}
        sortState={sortState}
        changeSortState={changeSortState}
      />
      <ButtonWrapper>
        {addConsignment?.type === "update" && (
          <Button
            fill="true"
            variant="primary"
            onClick={() => setAddConsignmentLine({ type: "create" })}
          >
            Add Line
          </Button>
        )}
      </ButtonWrapper>
    </Card>
  );
};

export default Lines;
