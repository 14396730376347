import React from "react";

import { Card } from "@lightspeed/flame/Card";
import { Input } from "@lightspeed/flame/Input";
import { Button } from "@lightspeed/flame/Button";

import { useStoreActions, useStoreState } from "easy-peasy";

const CategorieBar = () => {
  const {
    setCustomer,
    setUserCustomer,
    setSearchQuery,
    setAddType,
    setAddConsignment,
  } = useStoreActions((props) => props.ui);
  const { searchQuery, customerTable, addConsignment } = useStoreState(
    (props) => props.ui
  );
  const { user } = useStoreState((props) => props.auth);
  const { setLines } = useStoreActions((props) => props.consignments);

  if (addConsignment.type) return null;

  return (
    <Card
      className={`categories-bar flame`}
      // className={`categories-bar ${
      //   ui.theme === "flame" ? "" : "categories-bar-dark"
      // }`}
    >
      <Input
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        type="text"
        size="small"
        placeholder="Search"
        width="250px"
      />
      <div style={{ flex: "1" }} />

      <Button
        fill="true"
        variant="primary"
        onClick={() => {
          const createNew = () => {
            switch (customerTable) {
              case "send":
                setUserCustomer({ type: "create" });
                break;
              case "itemtype":
                setAddType({ type: "create_item" });
                break;
              case "servicetype":
                setAddType({ type: "create_service" });
                break;
              case "connotetype":
                setAddType({ type: "create_connote" });
                break;
              case "consignment":
                setAddConsignment({ type: "create" });
                setLines([]);

                break;
              default:
            }
          };
          user?.user_privileges === 99
            ? setCustomer({ type: "create" })
            : createNew();
        }}
      >
        {(customerTable === "admin" && "+ Create User") ||
          (customerTable === "send" && "+ Create Customer") ||
          (customerTable.includes("type") && "+ Create Type") ||
          (customerTable === "consignment" && "+ Create Consignment")}
      </Button>
    </Card>
  );
};

export default CategorieBar;
