import ModalWrapper from "./ModalWrapper";
import { Input } from "@lightspeed/flame/Input";
import { Button } from "@lightspeed/flame/Button";

import { useForm } from "react-hook-form";
import { useStoreActions, useStoreState } from "easy-peasy";

import { useEffect, useState } from "react";
import { useDebounce } from "use-debounce";
import styled from "@emotion/styled";

const InputWrapper = ({
  name,
  type = "text",
  placeholder,
  label,
  fwdRef,
  onChange,
}) => {
  return (
    <div className="input-wrapper-customer">
      <label htmlFor={name}>{label}</label>
      <Input
        autoComplete="off"
        id={name}
        name={name}
        type={type}
        placeholder={placeholder}
        ref={fwdRef}
        tabIndex="2"
        onChange={onChange}
      />
    </div>
  );
};

const FormWrapper = styled.div`
  ${(props) => props.theme && `background: #393939;`};
`;

const CreateCustomer = () => {
  const { register, handleSubmit, errors, setValue } = useForm();
  const { setCustomer } = useStoreActions((props) => props.ui);
  const { customer, theme } = useStoreState((props) => props.ui);
  const { user } = useStoreState((props) => props.auth);
  const { createUser, updateUser, getUsers, usernameCheck } = useStoreActions(
    (props) => props.users
  );
  const [errorText, errorTextSet] = useState("");
  const [userValue, userValueSet] = useState("");
  const [userAvailable, userAvailableSet] = useState("");
  const [value] = useDebounce(userValue, 1000);

  useEffect(() => {
    if (errors.username) {
      errorTextSet("Username is required");
      return;
    }
    if (errors.password) {
      errorTextSet("Password is required");
      return;
    }
    if (errors.password2) {
      errorTextSet("Password confirmation is required");
      return;
    }
    if (errors.fname) {
      errorTextSet("First name is required");
      return;
    }
    if (errors.lname) {
      errorTextSet("Last name is required");
      return;
    }
  }, [errors]);

  useEffect(() => {
    if (customer.type === "update") {
      setValue("fname", customer.item.user_fname);
      setValue("lname", customer.item.user_lname);
      setValue("phone", customer.item.user_phone);
      setValue("email", customer.item.user_email);
      setValue("password", customer.item.user_password);
      setValue("password2", customer.item.user_password);
    }
  }, [customer]);
  useEffect(() => {
    if (!value) return;
    const onSuccess = () => {
      userAvailableSet({ text: "available", color: "green" });
    };
    const onFail = () => {
      userAvailableSet({ text: "not available", color: "red" });
    };

    usernameCheck({ user, username: value, onSuccess, onFail });
  }, [value]);

  const onSubmit = (e) => {
    errorTextSet("");
    if (e.password !== e.password2) {
      errorTextSet("The passwords do not match");
      return;
    }

    const onSuccess = () => {
      getUsers({ user });
      setCustomer("");
    };

    customer.type === "create"
      ? createUser({ e, setCustomer, onSuccess, user })
      : updateUser({
          e: { ...e, uuid: customer.item.user_uuid },
          setCustomer,
          onSuccess,
          user,
        });
  };

  const handleUserChange = (e) => {
    userValueSet(e.target.value);
  };
  return (
    <ModalWrapper onClick={() => setCustomer("")}>
      <FormWrapper theme={theme !== "flame"} className="create-customer">
        <form
          className="create-customer-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          {customer.type === "create" && (
            <div className="username-wrapper">
              <InputWrapper
                span
                name="username"
                label="Username"
                fwdRef={register({ required: true })}
                onChange={handleUserChange}
              />

              {userAvailable.text && (
                <p style={{ color: userAvailable.color }}>
                  {userAvailable.text}
                </p>
              )}
            </div>
          )}
          <InputWrapper
            name="password"
            type="password"
            label="Password"
            fwdRef={register({ required: true })}
          />
          <InputWrapper
            name="password2"
            type="password"
            label="Confirm password"
            fwdRef={register({ required: true })}
          />
          <InputWrapper
            name="fname"
            label="First name"
            fwdRef={register({ required: true })}
          />
          <InputWrapper
            name="lname"
            label="Last name"
            fwdRef={register({ required: true })}
          />
          <InputWrapper
            name="email"
            type="email"
            label="Email"
            fwdRef={register}
          />
          <InputWrapper name="phone" label="Phone" fwdRef={register} />
          <p>{errorText}</p>
          <div className="form-buttons-wrapper">
            <Button
              fill="true"
              variant="primary"
              type="button"
              onClick={() => setCustomer("")}
            >
              Cancel
            </Button>
            <Button fill="true" variant="primary" type="submit">
              {customer.type === "create"
                ? "Create customer"
                : "Update customer"}
            </Button>
          </div>
        </form>
      </FormWrapper>
    </ModalWrapper>
  );
};

export default CreateCustomer;
