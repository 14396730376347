import { jsPDF } from "jspdf";
import QRCode from "qrcode";

import tableImg from "./table.png";

import "../assets/opensans.js";

export const generatePdf = (data, number) => {
  const doc = new jsPDF({
    unit: "cm",
    format: [15, 10],
    compress: true,
  });

  doc.setFont("OpenSans-Regular");

  const promises = [];

  const qrConfig = {
    errorCorrectionLevel: "H",
    type: "image/jpeg",
    quality: 0.3,
    margin: 0,
  };

  const createPage = (qrImg = "", item) => {
    doc.addImage(tableImg, "png", 0, 0, 10, 15);
    doc.addImage(qrImg, "jpeg", 6.5, 11.5, 2.8, 2.8);

    // font size 12
    doc.setFontSize(12);
    doc.text(item.cn, 0.5, 1.35);

    // font size 8
    doc.setFontSize(8);
    doc.text(item.title, 0.5, 0.8);

    // font size 10
    doc.setFontSize(10);
    doc.text(`Date: ${item.despatch_date}`, 6.6, 1.4);
    // 1 of 9
    doc.text(item.item, 8.5, 0.8);

    // sender
    doc.text("Sender", 0.5, 2.7);
    doc.text(item.sender_name, 3, 2.7);
    doc.text(item.sender_address, 3, 3.25);
    doc.text(item.sender_subrub, 3, 3.7);
    doc.text("Contact", 0.5, 4.3);
    doc.text(item.sender_contact, 3, 4.3);
    doc.text("Phone", 0.5, 4.85);
    doc.text(item.sender_phone, 3, 4.85);

    doc.setFontSize(12);

    // receiver
    doc.text("Receiver", 0.5, 6.3);
    doc.text(item.receiver_name, 3, 6.3);
    doc.text(item.receiver_address, 3, 7.3);
    doc.text(item.receiver_subrub, 3, 7.9);
    doc.text("Contact", 0.5, 9);
    doc.text(item.receiver_contact, 3, 9);
    doc.text("Phone", 0.5, 9.8);
    doc.text(item.receiver_phone, 3, 9.8);

    // bold
    doc.text("Receiver", 0.5, 6.3);
    doc.text(item.receiver_name, 3, 6.3);
    doc.text(item.receiver_address, 3, 7.3);
    doc.text(item.receiver_subrub, 3, 7.9);
    doc.text("Contact", 0.5, 9);
    doc.text(item.receiver_contact, 3, 9);
    doc.text("Phone", 0.5, 9.8);
    doc.text(item.receiver_phone, 3, 9.8);
    doc.text("Receiver", 0.5, 6.3);
    doc.text(item.receiver_name, 3, 6.3);
    doc.text(item.receiver_address, 3, 7.3);
    doc.text(item.receiver_subrub, 3, 7.9);
    doc.text("Contact", 0.5, 9);
    doc.text(item.receiver_contact, 3, 9);
    doc.text("Phone", 0.5, 9.8);
    doc.text(item.receiver_phone, 3, 9.8);

    // font size 8
    doc.setFontSize(10);

    // delivery instructions
    var split1 = doc.splitTextToSize(item.receiver_instruction, 5.85);
    var split2 = doc.splitTextToSize(item.special_instruction, 5.85);

    const space = [" "];

    doc.text([...split1, ...space, ...split2], 0.5, 11);

    // doc.text("Acc No: NATRSO", 0.95, 2.5);

    // Timeslot
    // doc.text("Timeslot", 0.2, 7.9);
    // doc.text("Date", 1.9, 7.9);
    // doc.text(item.date, 3.25, 7.9);
    // doc.text("Time", 6.15, 7.9);
    // doc.text(item.time, 8, 7.9);

    // Deliver ref
    // doc.text("Delivery Ref", 0.2, 8.4);
    // doc.text(item.ref, 3.25, 8.4);

    // Customer Ref
    // doc.text("Customer Ref", 0.6, 8.9);
    // doc.text("Unit", 4.15, 8.9);
    // doc.text("Description", 6, 8.9);
    // doc.text("Qty", 8.8, 8.9);

    // Lines
    // if (item.lines && item.lines[0]) {
    //   doc.text(item?.lines[0]?.customer_ref, 0.8, 9.3);
    //   doc.text(String(item?.lines[0]?.unit), 4.25, 9.3);
    //   doc.text(item?.lines[0]?.description, 6.2, 9.3);
    //   doc.text(String(item?.lines[0]?.qty), 8.9, 9.3);
    // }

    // if (item.lines && item.lines[1]) {
    //   doc.text(item?.lines[1]?.customer_ref, 0.8, 9.7);
    //   doc.text(String(item?.lines[1]?.unit), 4.25, 9.7);
    //   doc.text(item?.lines[1]?.description, 6.2, 9.7);
    //   doc.text(String(item?.lines[1]?.qty), 8.9, 9.7);
    // }
  };

  const createPageWithQr = async (item, index) => {
    const qrImg = await QRCode.toDataURL(item.qr, qrConfig);
    if (index !== 0) doc.addPage();
    createPage(qrImg, item);
  };

  data.forEach(async (item, index) => {
    promises[index] = await createPageWithQr(item, index);
  });

  Promise.all([...promises]).then((values) => {
    doc.save(`labels_${number}.pdf`);
  });
};
