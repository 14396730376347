import React from "react";

import { Td, Tr } from "@lightspeed/cirrus-table";

import { useStoreActions, useStoreState } from "easy-peasy";

import trash from "../../../../../assets/trash.png";
import whitetrash from "../../../../../assets/whitetrash.png";
import edit from "../../../../../assets/edit.png";
import whiteedit from "../../../../../assets/whiteedit.png";

const Row = ({ item }) => {
  const { setDeleteItem, setAddConsignmentLine } = useStoreActions(
    (props) => props.ui
  );

  const { theme } = useStoreState((props) => props.ui);
  const { itemTypes } = useStoreState((props) => props.types);

  const itemType = itemTypes.find((fitem) => fitem?.id === item?.item_id);

  return (
    <Tr
      textAlign="right"
      className="flame"
      onDoubleClick={() => setAddConsignmentLine({ type: "update", item })}
      className={`${theme === "flame" ? "" : "dark-table-row"}`}
    >
      <Td>{item.customer_ref}</Td>
      <Td>{item.qty}</Td>
      <Td>{item.item_name}</Td>
      <Td>{item.weight}</Td>
      <Td>{item.length}</Td>
      <Td>{item.width}</Td>
      <Td>{item.height}</Td>
      <Td>{item.cubic}</Td>
      {/* <Td>{getDate(item.created_at)}</Td> */}
      <Td>
        <img
          className="image-button"
          onClick={() => {
            setDeleteItem({ type: "line", id: item.line_id });
          }}
          src={theme === "flame" ? trash : whitetrash}
          alt="delete"
        />
        <img
          className="image-button"
          onClick={() => {
            setAddConsignmentLine({ type: "update", item });
          }}
          src={theme === "flame" ? edit : whiteedit}
          alt="edit"
        />
      </Td>
    </Tr>
  );
};

export default Row;
