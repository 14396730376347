export function getDate(inputDate) {
  if (!inputDate) return "";

  const input = new Date(inputDate);
  const month = input.getMonth() + 1;
  const day = input.getDate();
  const year = input.getFullYear();
  const formatedDate = month + "/" + day + "/" + year;
  return formatedDate;
}

export function getDateAndTime(inputDate) {
  const input = new Date(inputDate);
  if (!inputDate) return "";

  const formatedDate =
    input.getMonth() +
    1 +
    "." +
    input.getDate() +
    "." +
    input.getFullYear() +
    " " +
    input.getHours() +
    ":" +
    input.getMinutes() +
    ":" +
    input.getSeconds();
  return formatedDate;
}
