import styled from "@emotion/styled";
import { useStoreState } from "easy-peasy";
import { useEffect } from "react";

import { Input } from "@lightspeed/flame/Input";
import { Button } from "@lightspeed/flame/Button";
import Select from "react-select";

const GroupWrapper = styled.div`
  margin-right: 8px;

  label {
    margin-bottom: 6px;
    display: block;
  }
`;

const InputGroup = ({
  label,
  name,
  placeholder = "",
  type = "text",
  value,
  valueSet,
}) => {
  return (
    <GroupWrapper>
      <label htmlFor={name}>{label}</label>
      <Input
        autoComplete="off"
        type={type}
        value={value}
        onChange={(e) => valueSet(e.target.value)}
        name={name}
        id={name}
        placeholder={placeholder}
      />
    </GroupWrapper>
  );
};

const SelectGroup = ({ label, name, options, value, valueSet }) => {
  const { theme } = useStoreState((props) => props.ui);

  const customStyle = {
    option: (base, state) => ({
      ...base,
      backgroundColor: theme === "flame" ? "#fff" : "#333",
      color: theme === "flame" ? "#333" : "#fff",
    }),
  };
  return (
    <GroupWrapper>
      <label htmlFor={name}>{label}</label>
      <Select
        styles={customStyle}
        name={name}
        id={name}
        options={options}
        value={value}
        onChange={(e) => valueSet(e)}
      />
    </GroupWrapper>
  );
};

const TemplateStyle = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr) 0.5fr;
  width: 100%;
  align-items: center;
`;

const Flexed = styled.div`
  flex: 1;
`;

const FlexEnd = styled.div`
  align-self: flex-end;
`;

const Template = ({ props, updateStore, templateNotChanged }) => {
  const { serviceTypes, connoteTypes } = useStoreState((props) => props.types);
  const {
    templateCarrier,
    templateDate,
    templateWhoPays,
    selectService,
    selectConnote,
    templateCarrierSet,
    templateDateSet,
    templateWhoPaysSet,
    selectServiceSet,
    selectConnoteSet,
  } = props;

  const whoPaysList = ["Sender", "Receiver", "3rd Party"];

  useEffect(() => {
    let today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const yyyy = today.getFullYear();

    today = yyyy + "-" + mm + "-" + dd;
    templateDateSet(today);
  }, []);

  return (
    <TemplateStyle>
      <InputGroup
        value={templateCarrier}
        valueSet={templateCarrierSet}
        label="Carrier"
        name="carrier"
      />
      <InputGroup
        type="date"
        value={templateDate}
        valueSet={templateDateSet}
        label="Despatch Date"
        name="despatch_date"
      />

      <SelectGroup
        options={whoPaysList.map((item) => ({
          label: item,
          value: item,
        }))}
        label="Who Pays"
        name="who_pays"
        value={templateWhoPays}
        valueSet={templateWhoPaysSet}
      />
      <SelectGroup
        options={serviceTypes.map((item) => ({
          label: item.item_name,
          value: item.id,
        }))}
        label="Service"
        name="select_service"
        value={selectService}
        valueSet={selectServiceSet}
      />
      {/* <SelectGroup
        options={connoteTypes.map((item) => ({
          label: item.item_name,
          value: item.id,
        }))}
        label="Connote Type"
        name="select_connote"
        value={selectConnote}
        valueSet={selectConnoteSet}
      /> */}
      <Flexed />
      <FlexEnd>
        {!templateNotChanged && (
          <Button fill="true" variant="primary" onClick={updateStore}>
            Update
          </Button>
        )}
      </FlexEnd>
    </TemplateStyle>
  );
};

export default Template;
