import React from "react";

import { useStoreActions } from "easy-peasy";

const DarkMode = () => {
  const { toggleTheme } = useStoreActions((props) => props.ui);
  return (
    <div onClick={toggleTheme} className="dark-mode-button">
      Dark Mode
    </div>
  );
};

export default DarkMode;
