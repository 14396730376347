import React, { useState, useEffect } from "react";

import { Table, Thead, Tr, Th, Sortable } from "@lightspeed/cirrus-table";
import { Card } from "@lightspeed/flame/Card";

import Row from "./consignmentTable/Row";
import Loader from "../../Loader";

import * as sortFns from "../../../utils/sortFn";

import { useStoreState } from "easy-peasy";

const ConsignmentTable = ({ data, scrollPos, sortState, changeSortState }) => {
  const [sortedData, setSortedData] = useState([...data]);
  const sortValues = ["unsorted", "desc", "asc"];
  const reducer = (accumulator, currentValue) => accumulator + currentValue;
  const { searchQuery, theme } = useStoreState((props) => props.ui);

  useEffect(() => {
    setSortedData([...data]);
  }, [data]);

  const sortByData = [
    "",
    "",
    "id",
    "sender_name",
    "receiver_name",
    "despatch_date",
    "date_created",
    "date_updated",
  ];

  useEffect(() => {
    const res = sortState.reduce(reducer);
    if (res > 0) {
      if (res === 1) {
        setSortedData((oldSortedData) => [
          ...oldSortedData.sort(
            sortFns.customSortGen(
              sortByData[sortState.findIndex((i) => i === 1)]
            )
          ),
        ]);
      } else if (res === 2) {
        setSortedData((oldSortedData) => [
          ...oldSortedData
            .sort(
              sortFns.customSortGen(
                sortByData[sortState.findIndex((i) => i === 2)]
              )
            )
            .reverse(),
        ]);
      }
    } else {
      setSortedData([...data]);
    }
  }, [sortState, data]);

  if (sortedData.length === 0 && searchQuery) {
    return (
      <div className="loader-no-item">
        <h3>No items match your search</h3>
      </div>
    );
  }

  const headerData = [
    { id: 1, label: "L", width: "2%", sortable: false },
    { id: 2, label: "C", width: "2%", sortable: false },
    { id: 21, label: "M", width: "2%", sortable: false },
    { id: 3, label: "Connote No", width: "9%", sortable: true },
    { id: 4, label: "Sender Name", width: "18%", sortable: true },
    { id: 5, label: "Receiver Name", width: "18%", sortable: true },
    { id: 6, label: "Despatch date", width: "9%", sortable: true },
    { id: 7, label: "Date Created", width: "10%", sortable: true },
    { id: 8, label: "Date Updated", width: "10%", sortable: true },
    { id: 9, label: "Edit", width: "8%", sortable: false },
  ];

  return (
    <Card top="true">
      <Table>
        <Thead>
          {scrollPos === true ? (
            <Tr className={`${theme === "flame" ? "" : "dark-table"}`}>
              {headerData.map((item, index) =>
                item.sortable ? (
                  <Sortable.Th
                    key={item.id}
                    width={item.width}
                    direction={sortValues[sortState[index]]}
                    onClick={() => {
                      changeSortState(index);
                    }}
                  >
                    {item.label}
                  </Sortable.Th>
                ) : (
                  <Th
                    style={{ paddingLeft: "24px" }}
                    key={item.id}
                    width={item.width}    
                  >
                    {item.label}
                  </Th>
                )
              )}
            </Tr>
          ) : (
            <Tr className={`${theme === "flame" ? "" : "dark-table-fake"}`}>
              {headerData.map((item) => (
                <Th
                  key={item.id}
                  className="table-th-sticky"
                  width={item.width}
                >
                  {item.label}
                </Th>
              ))}
            </Tr>
          )}
        </Thead>
        <tbody className="t-body">
          {sortedData.map((item) => (
            <Row key={item.id} item={item} />
          ))}
        </tbody>
      </Table>
    </Card>
  );
};

export default ConsignmentTable;
