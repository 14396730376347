import ConsignmentTable from "./ConsignmentTable";

import { useStoreActions, useStoreState } from "easy-peasy";
import AddEdit from "./consignmentPage/AddEdit";

import { useEffect } from "react";

const ConsignmentPage = ({ props }) => {
  const { scrollState, searchedData, sortState, changeSortState } = props;
  const { addConsignment } = useStoreState((props) => props.ui);
  const { user } = useStoreState((props) => props.auth);
  const { getTemplate } = useStoreActions((props) => props.consignments);
  useEffect(() => {
    getTemplate({ user });
  }, []);

  if (addConsignment.type === "create") return <AddEdit />;
  if (addConsignment.type === "update") return <AddEdit />;

  return (
    <div>
      <ConsignmentTable
        scrollPos={scrollState}
        data={searchedData}
        sortState={sortState}
        changeSortState={changeSortState}
      />
    </div>
  );
};

export default ConsignmentPage;
