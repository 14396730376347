import axios from "axios";
import { action, thunk } from "easy-peasy";

const serverUrl = "https://api.ntsolutions.com.au/api/post";

const axiosGetLoggedIn = async (payload) => {
  const data = JSON.stringify({
    user_username: payload.user,
    user_password: payload.pass,
  });

  const options = {
    method: "POST",
    url: serverUrl,
    headers: {
      "X-Operation-Id": "GetLogin",
      "Content-Type": "application/json",
    },
    data: data,
  };
  const resp = await axios.request(options);
  return resp;
};

export const authModel = {
  user: "",
  setUser: action((state, payload) => {
    state.user = payload;
  }),
  getLoggedIn: thunk(async (actions, payload) => {
    axiosGetLoggedIn(payload)
      .then((resp) => {
        const res = resp.data[0];
        if (res.user_privileges && res.user_privileges === 99) {
          payload.onSuccess("admin");
        } else {
          payload.onSuccess("consignment");
        }
        actions.setUser(res);
      })
      .catch((err) => {
        payload.setNotification({ show: true, msg: "Login failed" });
        console.log(err);
      });
  }),
};
