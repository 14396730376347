import ModalWrapper from "./ModalWrapper";
import { Input } from "@lightspeed/flame/Input";
import { Button } from "@lightspeed/flame/Button";

import { useForm } from "react-hook-form";
import { useStoreActions, useStoreState } from "easy-peasy";

import { useEffect, useState } from "react";
import Select from "react-select";
import styled from "@emotion/styled";

const InputWrapper = ({
  name,
  type = "text",
  placeholder,
  label,
  fwdRef,
  onChange,
  step = 1,
  disabled = false,
  tabIndex
}) => {
  return (
    <div className="input-wrapper-create-line">
      <label htmlFor={name}>{label}</label>
      <Input
        id={name}
        name={name}
        type={type}
        placeholder={placeholder}
        ref={fwdRef}
        tabIndex={tabIndex}
        onChange={onChange}
        step={step}
        autoComplete="off"
        disabled={disabled}
      />
    </div>
  );
};

const FormWrapper = styled.div`
  ${(props) => props.theme && `background: #393939;`};
`;

const LabelStyle = styled.label`
  padding: 6px 0;
  display: block;
`;

const CreateLine = () => {
  const { register, handleSubmit, errors, setValue, getValues } = useForm();
  const { setAddConsignmentLine } = useStoreActions((props) => props.ui);
  const { addConsignmentLine, addConsignment, theme } = useStoreState(
    (props) => props.ui
  );
  const { user } = useStoreState((props) => props.auth);
  const { createLine, updateLine } = useStoreActions(
    (props) => props.consignments
  );
  const [errorText, errorTextSet] = useState("");
  const { itemTypes } = useStoreState((props) => props.types);
  const [selectItem, selectItemSet] = useState({ label: "", value: "" });

  const customStyle = {
    option: (base, state) => ({
      ...base,
      backgroundColor: theme === "flame" ? "#fff" : "#333",
      color: theme === "flame" ? "#333" : "#fff",
    }),
  };

  const fieldChange = (q) => {
    const qty1 = getValues("qty");
    const le = getValues("length");
    const wi = getValues("width");
    const he = getValues("height");
    // cubic calculation
    setValue("cubic", (qty1 * le * wi * he).toFixed(2));
  };

  // useEffect(() => {
  //   if (errors.name) {
  //     errorTextSet("Name is required");
  //     return;
  //   }
  // }, [errors]);

  useEffect(() => {
    if (!addConsignmentLine) return;
    if (addConsignmentLine.type === "update") {
      setValue("customer_ref", addConsignmentLine.item.customer_ref);
      setValue("description", addConsignmentLine.item.description);
      setValue("spaces", addConsignmentLine.item.spaces);
      setValue("qty", addConsignmentLine.item.qty);
      setValue("weight", addConsignmentLine.item.weight);
      setValue("length", addConsignmentLine.item.length);
      setValue("width", addConsignmentLine.item.width);
      setValue("height", addConsignmentLine.item.height);
      setValue("cubic", addConsignmentLine.item.cubic);
      selectItemSet({
        label: addConsignmentLine.item.item_name,
        value: addConsignmentLine.item.item_id,
      });
    }
  }, [addConsignmentLine]);

  const onSubmit = (e) => {
    errorTextSet("");

    if (!addConsignmentLine) return;

    const onSuccess = () => {
      setAddConsignmentLine("");
    };

    if (addConsignmentLine.type === "create") {
      createLine({
        user,
        e: { ...e, rkdokum_id: addConsignment.item.id },
        itemType: selectItem,
        onSuccess,
        errorTextSet,
      });
    }

    if (addConsignmentLine.type === "update") {
      updateLine({
        user,
        e: {
          ...e,
          id: addConsignmentLine.item.line_id,
        },
        itemType: selectItem,
        onSuccess,
        errorTextSet,
      });
    }
  };

  return (
    <ModalWrapper onClick={() => setAddConsignmentLine("")}>
      <FormWrapper theme={theme !== "flame"} className="create-customer">
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputWrapper
            name="customer_ref"
            placeholder=""
            label="Item Ref"
            fwdRef={register}
          />
          {/* <InputWrapper
            name="spaces"
            placeholder=""
            label="Spaces"
            fwdRef={register({ valueAsNumber: true })}
            type="number"
          />
          <InputWrapper
            name="description"
            placeholder=""
            label="Description"
            fwdRef={register}
          /> */}
          <InputWrapper
            name="qty"
            placeholder=""
            label="Qty"
            fwdRef={register({ required: true, valueAsNumber: true })}
            type="number"
            onChange={fieldChange}
          />
          <LabelStyle>Item type</LabelStyle>
          <Select
            onChange={(e) => selectItemSet(e)}
            value={selectItem}
            defaultValue={selectItem}
            options={itemTypes.map((item) => ({
              label: item.item_name,
              value: item.id,
            }))}
            styles={customStyle}
          />
          <InputWrapper
            name="weight"
            placeholder=""
            label="Item Weight"
            fwdRef={register({ required: true, valueAsNumber: true })}
            type="number"
            step="0.01"
          />
          <InputWrapper
            name="length"
            placeholder=""
            label="Length (M)"
            fwdRef={register({ required: true, valueAsNumber: true })}
            type="number"
            step="0.01"
            onChange={fieldChange}
          />
          <InputWrapper
            name="width"
            placeholder=""
            label="Width (M)"
            fwdRef={register({ required: true, valueAsNumber: true })}
            type="number"
            step="0.01"
            onChange={fieldChange}
          />
          <InputWrapper
            name="height"
            placeholder=""
            label="Height (M)"
            fwdRef={register({ required: true, valueAsNumber: true })}
            type="number"
            step="0.01"
            onChange={fieldChange}
          />
          <InputWrapper
            name="cubic"
            placeholder=""
            label="Cubic"
            fwdRef={register({ valueAsNumber: true })}
            type="number"
            step="0.01"
            disabled
          />
          <p>{errorText}</p>
          <div className="types-form-buttons-wrapper">
            <Button
              fill="true"
              variant="primary"
              type="button"
              onClick={() => setAddConsignmentLine("")}
            >
              Cancel
            </Button>
            <Button fill="true" variant="primary" type="submit">
              {addConsignmentLine.type &&
              addConsignmentLine.type.includes("create")
                ? "Create Line"
                : "Update Line"}
            </Button>
          </div>
        </form>
      </FormWrapper>
    </ModalWrapper>
  );
};

export default CreateLine;
