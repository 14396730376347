import React from "react";

import { Td, Tr } from "@lightspeed/cirrus-table";

import { useStoreActions, useStoreState } from "easy-peasy";

import trash from "../../../../assets/trash.png";
import whitetrash from "../../../../assets/whitetrash.png";
import edit from "../../../../assets/edit.png";
import whiteedit from "../../../../assets/whiteedit.png";

import { getDate } from "../../../../utils/dateUtil";

const Row = ({ item }) => {
  const { setDeleteItem, setUserCustomer } = useStoreActions(
    (props) => props.ui
  );
  const { theme } = useStoreState((props) => props.ui);

  return (
    <Tr
      textAlign="right"
      className="flame"
      onDoubleClick={() => setUserCustomer({ type: "update", item })}
      className={`${theme === "flame" ? "" : "dark-table-row"}`}
    >
      <Td>{item.name}</Td>
      <Td>{item.address_line_1}</Td>
      <Td>{item.address_line_2}</Td>
      <Td>{`${item.city} ${item.state} ${item.postal_code}`}</Td>
      <Td>{item.contact}</Td>
      <Td>{item.email}</Td>
      <Td>{item.phone}</Td>
      <Td>{item.fax}</Td>
      <Td>{item.sender_instructions}</Td>
      <Td>{item.receiver_instruction}</Td>
      <Td>{getDate(item.created_at)}</Td>
      <Td>
        <img
          className="image-button"
          onClick={() => {
            setDeleteItem({ type: "customer", id: item.id });
          }}
          src={theme === "flame" ? trash : whitetrash}
          alt="delete"
        />
        <img
          className="image-button"
          onClick={() => {
            setUserCustomer({ type: "update", item });
          }}
          src={theme === "flame" ? edit : whiteedit}
          alt="edit"
        />
      </Td>
    </Tr>
  );
};

export default Row;
