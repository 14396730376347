import React, { useState, useEffect } from "react";

import { Table, Thead, Tr, Th, Sortable } from "@lightspeed/cirrus-table";
import { Card } from "@lightspeed/flame/Card";

import Row from "./simpleTable/Row";
import Loader from "../../Loader";

import * as sortFns from "../../../utils/sortFn";

import { useStoreState } from "easy-peasy";

const SimpleTable = ({ data, scrollPos, sortState, changeSortState }) => {
  const [sortedData, setSortedData] = useState([...data]);
  const sortValues = ["unsorted", "desc", "asc"];
  const reducer = (accumulator, currentValue) => accumulator + currentValue;
  const { searchQuery, theme } = useStoreState((props) => props.ui);

  useEffect(() => {
    setSortedData([...data]);
  }, [data]);

  const sortByData = ["id", "item_name"];

  useEffect(() => {
    const res = sortState.reduce(reducer);
    if (res > 0) {
      if (res === 1) {
        setSortedData((oldSortedData) => [
          ...oldSortedData.sort(
            sortFns.customSortGen(
              sortByData[sortState.findIndex((i) => i === 1)]
            )
          ),
        ]);
      } else if (res === 2) {
        setSortedData((oldSortedData) => [
          ...oldSortedData
            .sort(
              sortFns.customSortGen(
                sortByData[sortState.findIndex((i) => i === 2)]
              )
            )
            .reverse(),
        ]);
      }
    } else {
      setSortedData([...data]);
    }
  }, [sortState, data]);

  if (sortedData.length === 0 && searchQuery) {
    return (
      <div className="loader-no-item">
        <h3>No items match your search</h3>
      </div>
    );
  }

  return (
    <Card top="true">
      <Table>
        <Thead>
          {scrollPos === true ? (
            <Tr className={`${theme === "flame" ? "" : "dark-table"}`}>
              <Sortable.Th
                width="10%"
                direction={sortValues[sortState[0]]}
                onClick={() => {
                  changeSortState(0);
                }}
              >
                Id
              </Sortable.Th>
              <Sortable.Th
                width="80%"
                direction={sortValues[sortState[1]]}
                onClick={() => {
                  changeSortState(1);
                }}
              >
                Name
              </Sortable.Th>
              <Th width="10%">Edit</Th>
            </Tr>
          ) : (
            <Tr className={`${theme === "flame" ? "" : "dark-table-fake"}`}>
              <Th className="table-th-sticky" width="10%">
                Id
              </Th>
              <Th className="table-th-sticky" width="80%">
                Name
              </Th>
              <Th width="10%">Edit</Th>
            </Tr>
          )}
        </Thead>
        <tbody className="t-body">
          {sortedData.map((item) => (
            <Row key={item.id} item={item} />
          ))}
        </tbody>
      </Table>
    </Card>
  );
};

export default SimpleTable;
