import React, { useState } from "react";
import styled from "@emotion/styled";

import DarkMode from "./sideBar/DarkMode";
import logo from "../../assets/logo.png";
import { useStoreActions, useStoreState } from "easy-peasy";

const SideButton = styled.div`
  position: relative;

  &:after {
    opacity: ${(props) => (props.currentButton ? 1 : 0)};
    position: absolute;
    content: "";
    right: -8px;
    top: 0;
    height: 100%;
    width: 3px;
    background: #00b7ff;
  }
`;

const SideBar = ({ isAdmin }) => {
  const [currentButton, currentButtonSet] = useState(5);
  const { setCustomerTable, setAddConsignment } = useStoreActions(
    (props) => props.ui
  );
  const { getCustomers } = useStoreActions((props) => props.customers);
  const { getConsignments, getSenders, getReceivers } = useStoreActions(
    (props) => props.consignments
  );
  const { getItemTypes, getServiceTypes, getConnoteTypes } = useStoreActions(
    (props) => props.types
  );
  const { user } = useStoreState((props) => props.auth);

  return (
    <div className="side-bar relative">
      <div className="side-bar-logo-wrapper">
        <img src={logo} alt="logo" width="32px" height="32px" />
      </div>
      {isAdmin ? (
        <>
          <SideButton
            currentButton={currentButton === 1}
            onClick={() => {
              currentButtonSet(1);
            }}
            className="side-label side-label-dash"
          >
            Home
          </SideButton>
          <SideButton
            currentButton={currentButton === 2}
            onClick={() => {
              currentButtonSet(2);
            }}
            className="side-label side-label-dash"
          >
            Customers
          </SideButton>
          <SideButton
            currentButton={currentButton === 3}
            onClick={() => {
              currentButtonSet(3);
            }}
            className="side-label side-label-dash"
          >
            Settings
          </SideButton>
        </>
      ) : (
        <>
         <SideButton
            currentButton={currentButton === 5}
            onClick={() => {
              setCustomerTable("consignment");
              currentButtonSet(5);
              setAddConsignment("");
              getConsignments({ user });
              getSenders({ user });
              getReceivers({ user });
            }}
            className="side-label side-label-dash"
          >
            Consignment
          </SideButton>
          <SideButton
            currentButton={currentButton === 1}
            onClick={() => {
              setAddConsignment("");
              setCustomerTable("send");
              currentButtonSet(1);
              getCustomers({ user });
            }}
            className="side-label side-label-dash"
          >
            Sender / Receiver
          </SideButton>
          <SideButton
            currentButton={currentButton === 2}
            onClick={() => {
              setAddConsignment("");
              setCustomerTable("itemtype");
              currentButtonSet(2);
              getItemTypes({ user });
            }}
            className="side-label side-label-dash"
          >
            Item types
          </SideButton>
          <SideButton
            currentButton={currentButton === 3}
            onClick={() => {
              setAddConsignment("");
              setCustomerTable("servicetype");
              currentButtonSet(3);
              getServiceTypes({ user });
            }}
            className="side-label side-label-dash"
          >
            Service types
          </SideButton>
          {/* <SideButton
            currentButton={currentButton === 4}
            onClick={() => {
              setAddConsignment("");
              setCustomerTable("connotetype");
              currentButtonSet(4);
              getConnoteTypes({ user });
            }}
            className="side-label side-label-dash"
          >
            Connote types
          </SideButton> */}
         
        </>
      )}

      {/* 
      <div className="side-list-inner-wrapper">
        <SideList
          setDashMain={setDashMain}
          label="Retail"
          list={database.retail}
          current={database.currentCompany}
          listN={1}
          openList={openList}
          setOpenList={setOpenList}
        />
     
      </div> */}
      <DarkMode />
    </div>
  );
};

export default SideBar;
