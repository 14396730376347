import { action } from "easy-peasy";

export const uiModel = {
  theme: "experimentaldark",
  // theme: "flame",
  setTheme: action((state, payload) => {
    state.theme = payload;
  }),
  toggleTheme: action((state, payload) => {
    if (state.theme === "flame") {
      localStorage.setItem("theme", JSON.stringify("experimentaldark"));
      state.theme = "experimentaldark";
    } else {
      localStorage.setItem("theme", JSON.stringify("flame"));
      state.theme = "flame";
    }
  }),
  customer: "",
  setCustomer: action((state, payload) => {
    state.customer = payload;
  }),
  userCustomer: "",
  setUserCustomer: action((state, payload) => {
    state.userCustomer = payload;
  }),
  addType: "",
  setAddType: action((state, payload) => {
    state.addType = payload;
  }),
  addConsignment: "",
  setAddConsignment: action((state, payload) => {
    state.addConsignment = payload;
  }),
  addConsignmentLine: "",
  setAddConsignmentLine: action((state, payload) => {
    state.addConsignmentLine = payload;
  }),
  deleteItem: { type: "" },
  setDeleteItem: action((state, payload) => {
    state.deleteItem = payload;
  }),
  searchQuery: "",
  setSearchQuery: action((state, payload) => {
    state.searchQuery = payload;
  }),
  customerTable: "",
  setCustomerTable: action((state, payload) => {
    state.customerTable = payload;
  }),
};
