import axios from "axios";
import { action, thunk } from "easy-peasy";

const axiosGetConsignments = async (payload) => {
  const { user } = payload;
  const options = {
    method: "post",
    url: `https://api.ntsolutions.com.au/api/post`,
    headers: {
      "X-Operation-Id": "APICList",
      "X-Company-Id": user.user_company,
      "X-User-Id": user.user_uuid,
    },
  };
  const resp = await axios.request(options);
  return resp;
};
const axiosGetConsignmentDetails = async (payload) => {
  const { user, id } = payload;
  const data = JSON.stringify({
    id,
  });
  const options = {
    method: "post",
    url: `https://api.ntsolutions.com.au/api/post`,
    headers: {
      "X-Operation-Id": "APICListEdit",
      "X-Company-Id": user.user_company,
      "X-User-Id": user.user_uuid,
    },
    data,
  };
  const resp = await axios.request(options);
  return resp;
};
const axiosGetSenders = async (payload) => {
  const { user } = payload;
  const options = {
    method: "post",
    url: `https://api.ntsolutions.com.au/api/post`,
    headers: {
      "X-Operation-Id": "ApiSenderList",
      "X-Company-Id": user.user_company,
      "X-User-Id": user.user_uuid,
    },
  };
  const resp = await axios.request(options);
  return resp;
};
const axiosGetReceivers = async (payload) => {
  const { user } = payload;
  const options = {
    method: "post",
    url: `https://api.ntsolutions.com.au/api/post`,
    headers: {
      "X-Operation-Id": "ApiReceiverList",
      "X-Company-Id": user.user_company,
      "X-User-Id": user.user_uuid,
    },
  };
  const resp = await axios.request(options);
  return resp;
};
const axiosGetTemplate = async (payload) => {
  const { user } = payload;
  const options = {
    method: "post",
    url: `https://api.ntsolutions.com.au/api/post`,
    headers: {
      "X-Operation-Id": "ApiTemplateSelect",
      "X-Company-Id": user.user_company,
      "X-User-Id": user.user_uuid,
    },
  };
  const resp = await axios.request(options);
  return resp;
};
const axiosSetTemplate = async (payload) => {
  const { user, e } = payload;
  const data = JSON.stringify({
    ...e,
  });
  const options = {
    method: "post",
    url: `https://api.ntsolutions.com.au/api/post`,
    headers: {
      "X-Operation-Id": "ApiTemplateSave",
      "X-Company-Id": user.user_company,
      "X-User-Id": user.user_uuid,
    },
    data,
  };
  const resp = await axios.request(options);
  return resp;
};
const axiosGetLines = async (payload) => {
  const { user, id } = payload;
  const data = JSON.stringify({
    id,
  });
  const options = {
    method: "post",
    url: `https://api.ntsolutions.com.au/api/post`,
    headers: {
      "X-Operation-Id": "APILinesEdit",
      "X-Company-Id": user.user_company,
      "X-User-Id": user.user_uuid,
    },
    data,
  };
  const resp = await axios.request(options);
  return resp;
};
const axiosCreateLine = async (payload) => {
  const { user, e, itemType } = payload;
  const data = JSON.stringify({
    ...e,
    item_id: itemType.value,
    item_name: itemType.label,
  });

  const options = {
    method: "post",
    url: `https://api.ntsolutions.com.au/api/post`,
    headers: {
      "Content-Type": "application/json",
      "X-Operation-Id": "APILinesAdd",
      "X-Company-Id": user.user_company,
      "X-User-Id": user.user_uuid,
    },
    data,
  };

  const resp = await axios.request(options);
  return resp;
};
const axiosUpdateLine = async (payload) => {
  const { user, e, itemType } = payload;

  const data = JSON.stringify({
    ...e,
    item_id: itemType.value,
  });

  const options = {
    method: "post",
    url: `https://api.ntsolutions.com.au/api/post`,
    headers: {
      "Content-Type": "application/json",
      "X-Operation-Id": "APILinesSave",
      "X-Company-Id": user.user_company,
      "X-User-Id": user.user_uuid,
    },
    data,
  };

  const resp = await axios.request(options);
  return resp;
};
const axiosDeleteLine = async (payload) => {
  const { user, id } = payload;
  const data = JSON.stringify({
    id,
  });
  const options = {
    method: "post",
    url: `https://api.ntsolutions.com.au/api/post`,
    headers: {
      "Content-Type": "application/json",
      "X-Operation-Id": "APICListDeleteLines",
      "X-Company-Id": user.user_company,
      "X-User-Id": user.user_uuid,
    },
    data,
  };
  const resp = await axios.request(options);
  return resp;
};
const axiosCreateConsignment = async (payload) => {
  const { user, e } = payload;
  const data = JSON.stringify({
    ...e,
  });
  const options = {
    method: "post",
    url: `https://api.ntsolutions.com.au/api/post`,
    headers: {
      "Content-Type": "application/json",
      "X-Operation-Id": "APICListEditSave",
      "X-Company-Id": user.user_company,
      "X-User-Id": user.user_uuid,
    },
    data,
  };
  const resp = await axios.request(options);
  return resp;
};
const axiosUpdateConsignment = async (payload) => {
  const { user, e } = payload;
  const data = JSON.stringify({
    ...e,
  });
  const options = {
    method: "post",
    url: `https://api.ntsolutions.com.au/api/post`,
    headers: {
      "Content-Type": "application/json",
      "X-Operation-Id": "APICListEditSave",
      "X-Company-Id": user.user_company,
      "X-User-Id": user.user_uuid,
    },
    data,
  };
  const resp = await axios.request(options);
  return resp;
};

const axiosDeleteConsignment = async (payload) => {
  const { id, user } = payload;

  const data = JSON.stringify({
    id,
  });

  const options = {
    method: "post",
    url: `https://api.ntsolutions.com.au/api/post`,
    headers: {
      "Content-Type": "application/json",
      "X-Operation-Id": "APICListDelete",
      "X-Company-Id": user.user_company,
      "X-User-Id": user.user_uuid,
    },
    data,
  };
  const resp = await axios.request(options);
  return resp;
};
const axiosGetInstructionList = async (payload) => {
  const { user } = payload;

  const options = {
    method: "post",
    url: `https://api.ntsolutions.com.au/api/post`,
    headers: {
      "Content-Type": "application/json",
      "X-Operation-Id": "APIPickupInstructionList",
      "X-Company-Id": user.user_company,
      "X-User-Id": user.user_uuid,
    },
  };
  const resp = await axios.request(options);
  return resp;
};

export const consignmentModel = {
  consignments: [],
  senders: [],
  receivers: [],
  setConsignments: action((state, payload) => {
    state.consignments = payload;
  }),
  setSenders: action((state, payload) => {
    state.senders = payload;
  }),
  setReceivers: action((state, payload) => {
    state.receivers = payload;
  }),
  getConsignments: thunk(async (actions, payload) => {
    const resp = await axiosGetConsignments(payload);
    actions.setConsignments(resp.data);
  }),
  template: "",
  setTemplate: action((state, payload) => {
    state.template = payload.length
      ? payload[0]
      : {
          carrier: "",
          connote_id: "",
          connote_name: "",
          id: "",
          service_id: "",
          service_name: "",
          uer_id: "",
          who_pays: "",
        };
  }),
  getTemplate: thunk(async (actions, payload) => {
    const resp = await axiosGetTemplate(payload);
    actions.setTemplate(resp.data);
  }),
  storeTemplate: thunk(async (actions, payload) => {
    axiosSetTemplate(payload)
      .then((resp) => payload.onSuccess())
      .catch((err) => console.log("store err", err));
  }),
  getSenders: thunk(async (actions, payload) => {
    const resp = await axiosGetSenders(payload);
    actions.setSenders(resp.data);
  }),
  getReceivers: thunk(async (actions, payload) => {
    const resp = await axiosGetReceivers(payload);
    actions.setReceivers(resp.data);
  }),
  consignmentDetails: "",
  setConsignmentDetails: action((state, payload) => {
    state.consignmentDetails = payload[0];
    if (payload[0].lines) {
      state.lines = payload[0].lines;
    } else {
      state.lines = [];
    }
  }),
  getConsignmentDetails: thunk(async (actions, payload) => {
    const resp = await axiosGetConsignmentDetails(payload);
    actions.setConsignmentDetails(resp.data);
  }),
  lines: [],
  setLines: action((state, payload) => {
    state.lines = payload;
  }),
  addToLines: action((state, payload) => {
    state.lines.push(payload);
  }),
  getLines: thunk(async (actions, payload) => {
    const resp = await axiosGetLines(payload);
    actions.setLines(resp.data);
  }),
  createLine: thunk(async (actions, payload) => {
    axiosCreateLine(payload)
      .then((resp) => {
        payload.onSuccess();
        actions.addToLines(resp.data[0]);
      })
      .catch((err) => console.log(err));
  }),
  updateLine: thunk(async (actions, payload) => {
    axiosUpdateLine(payload)
      .then((resp) => {
        payload.onSuccess();
        if (resp.data) actions.setLines(resp.data);
      })
      .catch((err) => console.log(err));
  }),
  removeLine: thunk(async (actions, payload) => {
    axiosDeleteLine(payload)
      .then((resp) => {
        actions.setLines(resp.data);
        payload.onSuccess();
      })
      .catch((err) => console.log(err));
  }),
  createConsignment: thunk((actions, payload) => {
    axiosCreateConsignment(payload)
      .then((resp) => {
        payload.onSuccess(resp.data[0]);
      })
      .catch((err) => console.log(err));
  }),
  updateConsignment: thunk((actions, payload) => {
    axiosUpdateConsignment(payload)
      .then((resp) => payload.onSuccess())
      .catch((err) => console.log(err));
  }),
  deleteConsignment: thunk((actions, payload) => {
    axiosDeleteConsignment(payload)
      .then((resp) => payload.onSuccess())
      .catch((err) => console.log({ err }));
  }),
  instructionList: [],
  setInstructionList: action((state, payload) => {
    state.instructionList = payload.map((item) => item.pickup_instruction);
  }),
  getInstructionList: thunk(async (actions, payload) => {
    const resp = await axiosGetInstructionList(payload);
    actions.setInstructionList(resp.data);
  }),
};
