import { useStoreActions, useStoreState } from "easy-peasy";
import Template from "./Template";
import SenderReceiver from "./SenderReceiver";
import Lines from "./Lines";
import { Card } from "@lightspeed/flame/Card";
import styled from "@emotion/styled";

import { useEffect, useState } from "react";

const StyledCard = styled(Card)`
  padding: 16px;
  margin-bottom: 16px;
`;

const AddEdit = () => {
  const { template, consignmentDetails, lines } = useStoreState(
    (props) => props.consignments
  );
  const { addConsignment } = useStoreState((props) => props.ui);
  const { user } = useStoreState((props) => props.auth);
  const { storeTemplate, getTemplate } = useStoreActions(
    (props) => props.consignments
  );

  // template
  const [templateCarrier, templateCarrierSet] = useState("");
  const [templateDate, templateDateSet] = useState("");
  const [templateWhoPays, templateWhoPaysSet] = useState("");
  const [selectService, selectServiceSet] = useState("");
  const [selectConnote, selectConnoteSet] = useState("");

  // sender
  const [sender, senderSet] = useState("");
  const [senderAddress1, senderAddress1Set] = useState("");
  const [senderAddress2, senderAddress2Set] = useState("");
  const [senderSuburb, senderSuburbSet] = useState("");
  const [senderContact, senderContactSet] = useState("");
  const [senderPhone, senderPhoneSet] = useState("");
  const [senderSendingCity, senderSendingCitySet] = useState("");
  const [senderInstructions, senderInstructionsSet] = useState("");

  // receiver
  const [receiver, receiverSet] = useState("");
  const [receiverAddress1, receiverAddress1Set] = useState("");
  const [receiverAddress2, receiverAddress2Set] = useState("");
  const [receiverSuburb, receiverSuburbSet] = useState("");
  const [receiverContact, receiverContactSet] = useState("");
  const [receiverPhone, receiverPhoneSet] = useState("");
  const [receiverInstructions, receiverInstructionsSet] = useState("");

  // additional
  const [specialInstruction, specialInstructionSet] = useState("");
  const [senderPickupInstruction, senderPickupInstructionSet] = useState({
    label: "",
    value: "",
  });
  const [receiverPickupInstrucion, receiverPickupInstrucionSet] = useState({
    label: "",
    value: "",
  });
  const [pickupDate, pickupDateSet] = useState("");
  const [readyTime, readyTimeSet] = useState("");
  const [closeTime, closeTimeSet] = useState("");
  const [deliveryDate, deliveryDateSet] = useState("");
  const [deliveryTime, deliveryTimeSet] = useState("");
  const [deliveryRef, deliveryRefSet] = useState("");

  const bothDatesSet = (x) => {
    templateDateSet(x);
    pickupDateSet(x);
  };

  const senderProps = {
    sender,
    senderSet,
    senderAddress1,
    senderAddress1Set,
    senderAddress2,
    senderAddress2Set,
    senderSuburb,
    senderSuburbSet,
    senderContact,
    senderContactSet,
    senderPhone,
    senderPhoneSet,
    senderSendingCity,
    senderSendingCitySet,
    senderInstructions,
    senderInstructionsSet,
    receiver,
    receiverSet,
    receiverAddress1,
    receiverAddress1Set,
    receiverAddress2,
    receiverAddress2Set,
    receiverSuburb,
    receiverSuburbSet,
    receiverContact,
    receiverContactSet,
    receiverPhone,
    receiverPhoneSet,
    receiverInstructions,
    receiverInstructionsSet,
    templateCarrier,
    templateDate,
    templateWhoPays,
    selectService,
    selectConnote,
    specialInstruction,
    specialInstructionSet,
    senderPickupInstruction,
    senderPickupInstructionSet,
    receiverPickupInstrucion,
    receiverPickupInstrucionSet,
    pickupDate,
    pickupDateSet,
    readyTime,
    readyTimeSet,
    closeTime,
    closeTimeSet,
    deliveryDate,
    deliveryDateSet,
    deliveryTime,
    deliveryTimeSet,
    deliveryRef,
    deliveryRefSet,
  };

  useEffect(() => {
    if (consignmentDetails && addConsignment?.type === "update") {
      // templateDateSet(consignmentDetails.despatch_date);
      senderSet({
        label: consignmentDetails.sender_name,
        value: { id: consignmentDetails.sender_id },
      });
      senderAddress1Set(consignmentDetails.sender_address1);
      senderAddress2Set(consignmentDetails.sender_address2);
      senderSuburbSet(consignmentDetails.sender_subrub);
      senderContactSet(consignmentDetails.sender_contact);
      senderPhoneSet(consignmentDetails.sender_phone);
      senderSendingCitySet(consignmentDetails.sender_city);
      senderInstructionsSet(consignmentDetails.sender_instruction);
      receiverSet({
        label: consignmentDetails.receiver_name,
        value: { id: consignmentDetails.receiver_id },
      });
      receiverAddress1Set(consignmentDetails.receiver_address1);
      receiverAddress2Set(consignmentDetails.receiver_address2);
      receiverSuburbSet(consignmentDetails.receiver_subrub);
      receiverContactSet(consignmentDetails.receiver_contact);
      receiverPhoneSet(consignmentDetails.receiver_phone);
      receiverInstructionsSet(consignmentDetails.receiver_instruction);
      templateCarrierSet(consignmentDetails.carrier);
      templateWhoPaysSet({
        label: consignmentDetails.who_pays,
        value: consignmentDetails.who_pays,
      });
      specialInstructionSet(consignmentDetails.special_instruction);
      senderPickupInstructionSet({
        label: consignmentDetails.pickup_req,
        value: consignmentDetails.pickup_req,
      });
      receiverPickupInstrucionSet({
        label: consignmentDetails.delivery_req,
        value: consignmentDetails.delivery_req,
      });
      // pickupDateSet(consignmentDetails.pickup_date);
      readyTimeSet(consignmentDetails.pickup_ready_time);
      closeTimeSet(consignmentDetails.pickup_close_time);
      // deliveryDateSet(consignmentDetails.delivery_date);
      // deliveryTimeSet(consignmentDetails.delivery_time);
      deliveryRefSet(consignmentDetails.delivery_reference);
      selectServiceSet({
        label: consignmentDetails.service_name,
        value: consignmentDetails.service_id,
      });
      selectConnoteSet({
        label: consignmentDetails.connote_name,
        value: consignmentDetails.connote_id,
      });
    }
  }, [consignmentDetails]);

  // template
  useEffect(() => {
    templateCarrierSet(template.carrier);
    templateWhoPaysSet({
      label: template.who_pays,
      value: template.who_pays,
    });
    selectServiceSet({
      label: template.service_name,
      value: template.service_id,
    });
    selectConnoteSet({
      label: template.connote_name,
      value: template.connote_id,
    });
  }, [template]);

  // template
  const templateNotChanged =
    template.carrier === templateCarrier &&
    template.who_pays === templateWhoPays.label &&
    template.service_name === selectService.label &&
    template.connote_name === selectConnote.label;

  // template
  const updateStore = () => {
    const onSuccess = () => {
      getTemplate({ user });
    };

    storeTemplate({
      e: {
        ...template,
        service_name: selectService.label,
        service_id: selectService.value,
        connote_name: selectConnote.label,
        connote_id: selectConnote.value,
        carrier: templateCarrier,
        who_pays: templateWhoPays.label,
      },
      user,
      onSuccess,
    });
  };

  // sender select
  useEffect(() => {
    if (sender.value && sender.value.name) {
      senderAddress1Set(sender.value.address_line_1);
      senderAddress2Set(sender.value.address_line_2);
      senderSuburbSet(sender.value.suburb);
      senderContactSet(sender.value.contact);
      senderPhoneSet(sender.value.phone);
      senderSendingCitySet(sender.value.city);
      senderInstructionsSet(sender.value.instruction);
    }
  }, [sender]);

  // receiver select
  useEffect(() => {
    if (receiver.value && receiver.value.name) {
      receiverAddress1Set(receiver.value.address_line_1);
      receiverAddress2Set(receiver.value.address_line_2);
      receiverSuburbSet(receiver.value.suburb);
      receiverContactSet(receiver.value.contact);
      receiverPhoneSet(receiver.value.phone);
      receiverInstructionsSet(receiver.value.instruction);
    }
  }, [receiver]);

  const onSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <form onSubmit={onSubmit}>
      <StyledCard>
        <Template
          props={{
            templateCarrier,
            templateDate,
            templateWhoPays,
            selectService,
            selectConnote,
            templateCarrierSet,
            templateDateSet: bothDatesSet,
            templateWhoPaysSet,
            selectServiceSet,
            selectConnoteSet,
          }}
          updateStore={updateStore}
          templateNotChanged={templateNotChanged}
        />
        <SenderReceiver props={senderProps} />
      </StyledCard>

      <Lines data={lines} />
    </form>
  );
};

export default AddEdit;
