import React, { useEffect } from "react";

import { BrowserRouter, Route, Switch } from "react-router-dom";

import Home from "./components/Home";
import LoginWrapper from "./components/LoginWrapper";
import ConfirmDelete from "./components/ConfirmDelete";

import { FlameTheme, FlameGlobalStyles } from "@lightspeed/flame/Core";

import { useStoreState, useStoreActions } from "easy-peasy";

import CreateCustomer from "./components/modals/CreateCustomer";
import UserCreateCustomer from "./components/modals/UserCreateCustomer";
import CreateType from "./components/modals/CreateType";
import CreateLine from "./components/modals/CreateLine";

const App = () => {
  // const ui = useSelector((state) => state.ui);
  const { user } = useStoreState((props) => props.auth);
  const {
    customer,
    userCustomer,
    addType,
    theme,
    addConsignmentLine,
  } = useStoreState((props) => props.ui);
  const { setTheme } = useStoreActions((props) => props.ui);
  const { getUsers } = useStoreActions((props) => props.users);
  const { getCustomers } = useStoreActions((props) => props.customers);
  const {
    getConsignments,
    getSenders,
    getReceivers,
    getInstructionList,
  } = useStoreActions((props) => props.consignments);
  const { getItemTypes, getServiceTypes, getConnoteTypes } = useStoreActions(
    (props) => props.types
  );

  useEffect(() => {
    const storage = JSON.parse(localStorage.getItem("theme"));
    if (storage) {
      setTheme(storage);
    }
  }, []);

  

  useEffect(() => {
    if (user) {
      console.log("A1");
      const getCustomersData = (user) => {
        getCustomers({ user });
        getItemTypes({ user });
        getServiceTypes({ user });
        getConnoteTypes({ user });
        getConsignments({ user });
        getSenders({ user });
        getReceivers({ user });
        getInstructionList({ user });
      };

      user?.user_privileges === 99
        ? getUsers({ user })
        : getCustomersData(user);
    }
  }, [user, getUsers]);

  

  return (
    <FlameTheme themeName={theme}>
      <FlameGlobalStyles themeName={theme} />
      <ConfirmDelete />
      <img id="barcode" style={{ display: "none" }} />
      <BrowserRouter>
        {user === "" ? (
          <Switch>
            <Route exact path="/recover">
              <LoginWrapper recover />
            </Route>
            <Route path="/">
              <LoginWrapper />
            </Route>
          </Switch>
        ) : (
          <>
            {customer && <CreateCustomer />}
            {userCustomer && <UserCreateCustomer />}
            {addType && <CreateType />}
            {addConsignmentLine && <CreateLine />}
            <Switch>
              <Route path="/">
                <Home user={user} />
              </Route>
            </Switch>
          </>
        )}
      </BrowserRouter>
    </FlameTheme>
  );
};

export default App;
