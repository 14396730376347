import { authModel } from "./models/authModel";
import { uiModel } from "./models/uiModel";
import { usersModel } from "./models/usersModel";
import { customersModel } from "./models/customersModel";
import { typesModel } from "./models/typesModel";
import { consignmentModel } from "./models/consignmentModel";
import { pdfModel } from "./models/pdfModel";

export const rootModel = {
  ui: uiModel,
  auth: authModel,
  users: usersModel,
  customers: customersModel,
  types: typesModel,
  consignments: consignmentModel,
  pdf: pdfModel,
};
