import { jsPDF } from "jspdf";
import JsBarcode from "jsbarcode";

import connoteImg from "./connote.png";

import "../assets/opensans.js";

export const generatePdf2 = (data, number) => {
  const doc = new jsPDF({
    unit: "mm",
    format: [297, 210],
    compress: true,
  });

  // const myObj = <canvas id="barcode"></canvas>;

  // const jpegUrl = myCanvas.toDataURL("image/jpeg");
  // doc.addImage(
  //   <img src={`data:image/gif;base64,${myObj.encodings[0].data}`} />,
  //   "JPEG",
  //   0,
  //   0
  // );

  doc.setFont("OpenSans-Regular");

  const promises = [];

  const createPage = (item, index) => {
    if (index !== 0) doc.addPage();

    doc.addImage(connoteImg, "png", 5, 3, 200, 280);

    JsBarcode("#barcode", item.qr, {
      height: 20,
      margin: 0,
      displayValue: false,
    });

    const barcodeImg = document.getElementById("barcode");
    doc.addImage(barcodeImg, "jpeg", 140, 37, 40, 6);

    // font size 18
    doc.setFontSize(18);
    doc.text(item.label, 139.5, 32);

    // font size 16
    doc.setFontSize(16);
    doc.text("CONSIGNMENT NOTE", 134, 23);

    let copyText = "";
    if (index === 0) {
      copyText = "RECEIVER COPY";
    }
    if (index === 1) {
      copyText = "POD COPY";
    }
    // font size 14
    doc.setFontSize(14);
    doc.text(copyText, 82, 23);

    // bold
    doc.text(copyText, 82, 23);

    // doc.text(String(item.total3), 158, 83);

    // font size 9
    // doc.setFontSize(9);
    // doc.text(item.cannote, 21, 27);
    // doc.text(item.cannote, 21, 27);

    // if (index === 0) doc.text("Senders Copy", 93.5, 19);
    // if (index === 1) doc.text("Receivers Copy", 93.5, 19);
    // if (index === 2) doc.text("POD Copy", 93.5, 19);

    // font size 9
    doc.setFontSize(9);

    // sender
    doc.text("Sender", 9, 56);

    // bold
    doc.text("Sender", 9, 56);
    doc.text("Sender", 9, 56);

    doc.text(item.sender_name, 42.5, 62.5);
    doc.text(item.sender_address, 42.5, 67.5);
    doc.text(item.sender_subrub, 42.5, 72.5);

    doc.text("Contact", 9, 79);
    doc.text(item.sender_contact, 42.5, 79);

    doc.text("Phone", 9, 84.7);
    doc.text(item.sender_phone, 42.5, 84.7);

    doc.text("Sender instruction:", 9, 90.2);

    const split1 = doc.splitTextToSize(item.sender_instruction, 55);

    doc.text(split1, 42.5, 90.2);

    // receiver
    doc.text("Receiver", 98.5, 56);

    // bold
    doc.text("Receiver", 98.5, 56);
    doc.text("Receiver", 98.5, 56);

    doc.text(item.receiver_name, 114.5, 62.5);
    doc.text(item.receiver_address, 114.5, 67.5);
    doc.text(item.receiver_subrub, 114.5, 72.5);

    doc.text("Contact", 98.5, 79);
    doc.text(item.receiver_contact, 114.5, 79);

    doc.text("Phone", 98.5, 84.7);
    doc.text(item.receiver_phone, 114.5, 84.7);

    doc.text("Receiver instructions:", 98.5, 90.2);

    const split2 = doc.splitTextToSize(item.receiver_instruction, 55);

    doc.text(split2, 146, 90.2);

    // sender/receiver reference
    doc.text("Sender Reference", 9, 112.5);
    doc.text("Receiver Reference", 98.5, 112.5);

    const tableX = [9, 64, 67, 112, 127, 143, 162, 175, 200];

    // table labels
    doc.text("Item ref", 9, 118);
    doc.text("Item Count", 43, 118);
    doc.text("Description", 67, 118);
    doc.text("Length", 98, 118);
    doc.text("Width", 114.3, 118);
    doc.text("Height", 130, 118);
    doc.text("Cube", 145.7, 118);
    doc.text("Kg", 165, 118);
    doc.text("Total", 177.5, 118);

    if (item.lines?.length > 0) {
      let x = 5.3;
      item.lines.forEach((i, index) => {
        doc.text(String(i.item_ref), tableX[0], 124 + x * index, {
          align: "left",
        });
        doc.text(String(i.count), tableX[1], 124 + x * index, {
          align: "right",
        });
        doc.text(i.description, tableX[2], 124 + x * index, {
          align: "left",
        });
        doc.text(String(i.length), tableX[3], 124 + x * index, {
          align: "right",
        });
        doc.text(String(i.width), tableX[4], 124 + x * index, {
          align: "right",
        });
        doc.text(String(i.height), tableX[5], 124 + x * index, {
          align: "right",
        });
        doc.text(String(i.cubic), tableX[6], 124 + x * index, {
          align: "right",
        });
        doc.text(i.kg, tableX[7], 124 + x * index, {
          align: "right",
        });
        doc.text(i.total, tableX[8], 124 + x * index, {
          align: "right",
        });
      });
    }

    doc.text("Total", 33, 194);
    doc.text(item.total1 ? String(item.total1) : "", 65, 194, {
      align: "right",
    });


    doc.text(item.totalcube ? String(item.totalcube) : "", 163, 194, {
      align: "right",
    });

    
    doc.text(item.totalkkg ? String(item.totalkkg) : "", 176, 194, {
      align: "right",
    });
    

    if (index === 0) {
      doc.text("Driver name", 16, 271);
      doc.text("Driver signature", 87, 271);
      doc.text("Date", 157, 271);
    }
    if (index === 1) {
      doc.text("Receiver name", 13, 271);
      doc.text("Receiver signature", 84, 271);
      doc.text("Date", 157, 271);
    }
    

    // font size 10
    doc.setFontSize(10);

    // doc.text("RECEIVED IN GOOD ORDER AND CONDITION", 9, 205);

    // font size 12
    doc.setFontSize(12);

    doc.text("Total KG", 146, 205);
    doc.text(item.total3 ? String(item.total3) : "", 200, 205, {
      align: "right",
    });

    // bold
    doc.text("Total KG", 146, 205);
    doc.text(item.total3 ? String(item.total3) : "", 200, 205, {
      align: "right",
    });
  };

  data.forEach(async (item, index) => {
    // promises[index] = await createPageWithQr(item, index);
    createPage(item, index);
  });

  Promise.all([...promises]).then((values) => {
    doc.save(`connote_${number}.pdf`);
  });
};
