import axios from "axios";
import { action, thunk } from "easy-peasy";

const axiosGetPdf = async (payload) => {
  const { user, id } = payload;
  const data = JSON.stringify({
    id,
  });

  const options = {
    method: "post",
    url: `https://api.ntsolutions.com.au/api/post`,
    headers: {
      "X-Operation-Id": "PDFPrint1",
      "X-Company-Id": user.user_company,
      "X-User-Id": user.user_uuid,
    },
    data,
  };
  const resp = await axios.request(options);
  return resp;
};
const axiosGetPdf2 = async (payload) => {
  const { user, id } = payload;
  const data = JSON.stringify({
    id,
  });

  const options = {
    method: "post",
    url: `https://api.ntsolutions.com.au/api/post`,
    headers: {
      "X-Operation-Id": "PDFPrint2",
      "X-Company-Id": user.user_company,
      "X-User-Id": user.user_uuid,
    },
    data,
  };
  const resp = await axios.request(options);
  return resp;
};
const axiosGetPdf3 = async (payload) => {
  const { user, id } = payload;
  const data = JSON.stringify({
    id,
  });

  const options = {
    method: "post",
    url: `https://api.ntsolutions.com.au/api/post`,
    headers: {
      "X-Operation-Id": "PDFPrint3",
      "X-Company-Id": user.user_company,
      "X-User-Id": user.user_uuid,
    },
    data,
  };
  const resp = await axios.request(options);
  return resp;
};

export const pdfModel = {
  pdf: [],
  pdf2: [],
  pdf3: [],
  setPdf: action((state, payload) => {
    state.pdf = payload;
  }),
  setPdf2: action((state, payload) => {
    state.pdf2 = payload;
  }),
  setPdf3: action((state, payload) => {
    state.pdf3 = payload;
  }),
  getPdf: thunk(async (actions, payload) => {
    axiosGetPdf(payload)
      .then((resp) => {
        actions.setPdf(resp.data);
        payload.onSuccess(resp.data, payload.number);
      })
      .catch((err) => console.log(err));
  }),
  getPdf2: thunk(async (actions, payload) => {
    axiosGetPdf2(payload)
      .then((resp) => {
        actions.setPdf2(resp.data);
        payload.onSuccess(resp.data, payload.number);
      })
      .catch((err) => console.log(err));
  }),
  getPdf3: thunk(async (actions, payload) => {
    axiosGetPdf3(payload)
      .then((resp) => {
        actions.setPdf3(resp.data);
        payload.onSuccess(resp.data, payload.number);
      })
      .catch((err) => console.log(err));
  }),
};
