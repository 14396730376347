import React, { useState, useEffect } from "react";

import { Table, Thead, Tr, Th, Sortable } from "@lightspeed/cirrus-table";
import { Card } from "@lightspeed/flame/Card";

import Row from "./Row";
import Loader from "../../../../Loader";

import * as sortFns from "../../../../../utils/sortFn";

import { useStoreState } from "easy-peasy";

const LinesTable = ({ data, scrollPos = true, sortState, changeSortState }) => {
  const [sortedData, setSortedData] = useState([...data]);
  const sortValues = ["unsorted", "desc", "asc"];
  const reducer = (accumulator, currentValue) => accumulator + currentValue;
  const { searchQuery, theme } = useStoreState((props) => props.ui);

  useEffect(() => {
    setSortedData([...data]);
  }, [data]);

  const sortByData = [
    "customer_ref",
    "qty",
    "item_name",
    "weight",
    "length",
    "width",
    "height",
    "cubic",
  ];

  useEffect(() => {
    const res = sortState.reduce(reducer);
    if (res > 0) {
      if (res === 1) {
        setSortedData((oldSortedData) => [
          ...oldSortedData.sort(
            sortFns.customSortGen(
              sortByData[sortState.findIndex((i) => i === 1)]
            )
          ),
        ]);
      } else if (res === 2) {
        setSortedData((oldSortedData) => [
          ...oldSortedData
            .sort(
              sortFns.customSortGen(
                sortByData[sortState.findIndex((i) => i === 2)]
              )
            )
            .reverse(),
        ]);
      }
    } else {
      setSortedData([...data]);
    }
  }, [sortState, data]);

  if (sortedData.length === 0 && searchQuery) {
    return (
      <div className="loader-no-item">
        <h3>No items match your search</h3>
      </div>
    );
  }

  const headerData = [
    { id: 1, label: "Item Ref", width: "10%", sortable: true },
    { id: 4, label: "Qty", width: "10%", sortable: true },
    { id: 5, label: "Item type", width: "10%", sortable: true },
    { id: 6, label: "Item Weight", width: "10%", sortable: true },
    { id: 7, label: "Length (M)", width: "10%", sortable: true },
    { id: 8, label: "Width (M)", width: "10%", sortable: true },
    { id: 9, label: "Height (M)", width: "10%", sortable: true },
    { id: 10, label: "Cubic", width: "10%", sortable: true },
    { id: 11, label: "Edit", width: "10%", sortable: false },
  ];

  return (
    <Card top="true">
      <Table>
        <Thead>
          {scrollPos === true ? (
            <Tr className={`${theme === "flame" ? "" : "dark-table"}`}>
              {headerData.map((item, index) =>
                item.sortable ? (
                  <Sortable.Th
                    key={item.id}
                    width={item.width}
                    direction={sortValues[sortState[index]]}
                    onClick={() => {
                      changeSortState(index);
                    }}
                  >
                    {item.label}
                  </Sortable.Th>
                ) : (
                  <Th key={item.id} width={item.width}>
                    {item.label}
                  </Th>
                )
              )}
            </Tr>
          ) : (
            <Tr className={`${theme === "flame" ? "" : "dark-table-fake"}`}>
              {headerData.map((item) => (
                <Th
                  key={item.id}
                  className="table-th-sticky"
                  width={item.width}
                >
                  {item.label}
                </Th>
              ))}
            </Tr>
          )}
        </Thead>
        <tbody className="t-body">
          {sortedData.map((item) => (
            <Row key={Math.random()} item={item} />
          ))}
        </tbody>
      </Table>
    </Card>
  );
};

export default LinesTable;
