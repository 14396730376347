import axios from "axios";
import { action, thunk } from "easy-peasy";

const axiosGetCustomers = async (payload) => {
  const { user } = payload;
  const options = {
    method: "post",
    url: `https://api.ntsolutions.com.au/api/post`,
    headers: {
      "X-Operation-Id": "GetCustomers",
      "X-Company-Id": user.user_company,
      "X-User-Id": user.user_uuid,
    },
  };
  const resp = await axios.request(options);
  return resp;
};

const axiosCreateCustomer = async (payload) => {
  const { user, e, formatedAddress, streetNumber } = payload;

  const data = JSON.stringify([
    {
      id: 1,
      name: e.name,
      contact: e.contact,
      email: e.email,
      phone: e.phone,
      fax: e.fax,
      note: e.note,
      sender_instructions: e.sender_instructions,
      is_receiver: e.is_receiver ? 1 : 0,
      is_sender: e.is_sender ? 1 : 0,
      receiver_instruction: e.receiver_instructions,
      address_line_1: e.address1,
      address_line_2: e.address2,
      street_number: streetNumber,
      city: e.city,
      country: e.country,
      postal_code: e.postal_code,
      formatted_address: formatedAddress,
      lat: e.lat,
      lng: e.lng,
      state: e.state,
      created_at: "2021-03-16T14:36:04.297",
      updated_at: "2021-03-16T14:36:04.297",
    },
  ]);

  const options = {
    method: "post",
    url: `https://api.ntsolutions.com.au/api/post`,
    headers: {
      "X-Operation-Id": "CustomerInsert",
      "X-Company-Id": user.user_company,
      "X-User-Id": user.user_uuid,
      "Content-Type": "application/json",
    },
    data: data,
  };
  const resp = await axios.request(options);
  return resp;
};
const axiosUpdateCustomer = async (payload) => {
  const { user, e, formatedAddress, streetNumber, id } = payload;

  const data = JSON.stringify([
    {
      id: id,
      name: e.name,
      contact: e.contact,
      email: e.email,
      phone: e.phone,
      fax: e.fax,
      note: e.note,
      sender_instructions: e.sender_instructions,
      is_receiver: e.is_receiver ? 1 : 0,
      is_sender: e.is_sender ? 1 : 0,
      receiver_instruction: e.receiver_instructions,
      address_line_1: e.address1,
      address_line_2: e.address2,
      street_number: streetNumber,
      city: e.city,
      country: e.country,
      postal_code: e.postal_code,
      formatted_address: formatedAddress,
      state: e.state,
      lat: e.lat,
      lng: e.lng,
    },
  ]);

  const options = {
    method: "post",
    url: `https://api.ntsolutions.com.au/api/post`,
    headers: {
      "X-Operation-Id": "CustomerUpdate",
      "X-Company-Id": user.user_company,
      "X-User-Id": user.user_uuid,
      "Content-Type": "application/json",
    },
    data: data,
  };
  const resp = await axios.request(options);
  return resp;
};

const axiosDeleteCustomer = async (payload) => {
  const { user, id } = payload;
  const data = JSON.stringify({
    id,
  });

  const options = {
    method: "post",
    url: `https://api.ntsolutions.com.au/api/post`,
    headers: {
      "X-Operation-Id": "CustomerDelete",
      "X-Company-Id": user.user_company,
      "X-User-Id": user.user_uuid,
      "Content-Type": "application/json",
    },
    data: data,
  };
  const resp = await axios.request(options);
  return resp;
};

export const customersModel = {
  customers: [],
  setCustomers: action((state, payload) => {
    state.customers = payload;
  }),
  getCustomers: thunk(async (actions, payload) => {
    const resp = await axiosGetCustomers(payload);
    actions.setCustomers(resp.data);
  }),
  createCustomer: thunk((actions, payload) => {
    axiosCreateCustomer(payload)
      .then((resp) => payload.onSuccess())
      .catch((err) => payload.errorTextSet("Create customer failed!"));
  }),
  deleteCustomer: thunk((actions, payload) => {
    axiosDeleteCustomer(payload)
      .then((resp) => {
        payload.onSuccess();
      })
      .catch((err) => console.log(err));
  }),
  updateCustomer: thunk((actions, payload) => {
    axiosUpdateCustomer(payload)
      .then((resp) => payload.onSuccess())
      .catch((err) => payload.errorTextSet("Update customer failed!"));
  }),
};
