import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import GlobarError from "./GlobalError";

import { StoreProvider, createStore } from "easy-peasy";
import { rootModel } from "./store/rootModel";
import logger from "redux-logger";

//css
import "./css/root.css";
import "./css/index.css";
import "./css/login.css";
import "./css/sidebar.css";
import "./css/location.css";
import "./css/dark.css";
import "./css/loader.css";
import "./css/modals.css";

const store = createStore(rootModel);
// const store = createStore(rootModel, { middleware: [logger] });

ReactDOM.render(
  <>
    {/* <GlobarError> */}
    <StoreProvider store={store}>
      <App />
    </StoreProvider>
    {/* </GlobarError> */}
  </>,
  document.getElementById("root")
);
