import React from "react";
import { Dialog } from "@lightspeed/flame/Dialog";

import { useStoreState, useStoreActions } from "easy-peasy";

const ConfirmDelete = () => {
  const { deleteItem, addConsignment } = useStoreState((props) => props.ui);
  const { setDeleteItem } = useStoreActions((props) => props.ui);
  const {
    deleteItemType,
    deleteServiceType,
    deleteconnoteType,
    getItemTypes,
    getServiceTypes,
    getConnoteTypes,
  } = useStoreActions((props) => props.types);
  const { removeLine, getConsignments, deleteConsignment } = useStoreActions(
    (props) => props.consignments
  );
  const { deleteUser, getUsers } = useStoreActions((props) => props.users);
  const { deleteCustomer, getCustomers } = useStoreActions(
    (props) => props.customers
  );
  const { user } = useStoreState((props) => props.auth);

  return (
    <Dialog
      title={"Remove item"}
      message={`Are you sure you want to remove this item?`}
      isOpen={!!deleteItem.type}
      onCancel={() => setDeleteItem({ type: "" })}
      onConfirm={() => {
        const onSuccess = () => {
          if (deleteItem.type === "admin") getUsers({ user });
          if (deleteItem.type === "customer") getCustomers({ user });
          if (deleteItem.type === "item") getItemTypes({ user });
          if (deleteItem.type === "service") getServiceTypes({ user });
          if (deleteItem.type === "connote") getConnoteTypes({ user });
          if (deleteItem.type === "consignment") getConsignments({ user });

          setDeleteItem({ type: "" });
        };

        if (deleteItem.type === "admin")
          deleteUser({ user, id: deleteItem.id, onSuccess });
        if (deleteItem.type === "customer")
          deleteCustomer({ user, id: deleteItem.id, onSuccess });
        if (deleteItem.type === "item")
          deleteItemType({ user, id: deleteItem.id, onSuccess });
        if (deleteItem.type === "service")
          deleteServiceType({ user, id: deleteItem.id, onSuccess });
        if (deleteItem.type === "connote")
          deleteconnoteType({ user, id: deleteItem.id, onSuccess });
        if (deleteItem.type === "consignment")
          deleteConsignment({ user, id: deleteItem.id, onSuccess });
        if (deleteItem.type === "line")
          removeLine({ user, id: deleteItem.id, onSuccess });
      }}
      cancelText="Cancel"
      confirmText="Delete"
      type="danger"
    />
  );
};

export default ConfirmDelete;
