import React from "react";
import pdf from "../../../../assets/pdf.png";

import styled from "@emotion/styled";
import { Td, Tr } from "@lightspeed/cirrus-table";

import { useStoreActions, useStoreState } from "easy-peasy";

import trash from "../../../../assets/trash.png";
import whitetrash from "../../../../assets/whitetrash.png";
import edit from "../../../../assets/edit.png";
import whiteedit from "../../../../assets/whiteedit.png";

import { getDateAndTime } from "../../../../utils/dateUtil";

import { generatePdf } from "../../../../utils/generatePdf";
import { generatePdf2 } from "../../../../utils/generatePdf2";
import { generatePdf3 } from "../../../../utils/generatePdf3";

const IconImg = styled.img`
  height: auto;
  width: 32px;
  cursor: pointer;
`;

const Row = ({ item }) => {
  const { setDeleteItem, setAddConsignment } = useStoreActions(
    (props) => props.ui
  );
  const { getConsignmentDetails, setLines } = useStoreActions(
    (props) => props.consignments
  );
  const { getPdf, getPdf2, getPdf3 } = useStoreActions((props) => props.pdf);
  const { theme } = useStoreState((props) => props.ui);
  const { user } = useStoreState((props) => props.auth);

  return (
    <Tr
      textAlign="right"
      className="flame"
      onDoubleClick={() => {
        setAddConsignment({ type: "update", item });
        getConsignmentDetails({ user, id: item.id });
      }}
      className={`${theme === "flame" ? "" : "dark-table-row"}`}
    >
      <Td>
        <IconImg
          onClick={() => {
            getPdf({
              user,
              id: item.id,
              onSuccess: generatePdf,
              number: item.id,
            });
          }}
          src={pdf}
          alt="pdf"
        />
      </Td>
      <Td>
        <IconImg
          onClick={() => {
            getPdf2({
              user,
              id: item.id,
              onSuccess: generatePdf2,
              number: item.id,
            });
          }}
          src={pdf}
          alt="pdf"
        />
      </Td>
      <Td>
        <IconImg
          onClick={() => {
            getPdf3({
              user,
              id: item.id,
              onSuccess: generatePdf3,
              number: item.id,
            });
          }}
          src={pdf}
          alt="pdf"
        />
      </Td>
      <Td>{item.id}</Td>
      <Td>{item.sender_name}</Td>
      <Td>{item.receiver_name}</Td>
      <Td>{item.despatch_date}</Td>
      <Td>{getDateAndTime(item.date_created)}</Td>
      <Td>{getDateAndTime(item.date_update)}</Td>
      <Td>
        <img
          className="image-button"
          onClick={() => {
            setDeleteItem({ type: "consignment", id: item.id });
          }}
          src={theme === "flame" ? trash : whitetrash}
          alt="delete"
        />
        <img
          className="image-button"
          onClick={() => {
            setLines([]);
            setAddConsignment({ type: "update", item });
            getConsignmentDetails({ user, id: item.id });
          }}
          src={theme === "flame" ? edit : whiteedit}
          alt="edit"
        />
      </Td>
    </Tr>
  );
};

export default Row;
